/*
@media (max-width: 1300px) {
	.table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    border: none;
  }
  .table-responsive > .table {
    margin-bottom: 0;
    background-color: #fff;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > thead > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > thead > tr:last-child > td,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
*/
@media (max-width: 1250px) {
  .ie8 .hidden-1250 {
    display: table-cell;
  }
  .page-content {
    min-height: 1300px !important;
  }
  .hidden-1250 {
    display: none;
  }
  .btn-wrap {
    width: 100% !important;
  }
  .btn-group {
    /* float: left !important; */
    /* padding-top: 0 !important; */
  }
}

@media (max-width: 1110px) {
  .ie8 .hidden-1110 {
    display: table-cell;
  }
  .hidden-1110 {
    display: none;
  }
}
@media (max-width: 1000px) {
  .ie8 .hidden-1000 {
    display: table-cell;
  }

  .hidden-1000 {
    display: none;
  }
}

@media (min-width: 980px) {
  /***
 	Page sidebar
 	***/
  .page-sidebar {
    position: absolute;
    width: 250px;
  }

  .page-sidebar-fixed .page-sidebar {
    position: fixed !important;
    top: 41px;
  }

  .page-sidebar-fixed ul.page-sidebar-menu > li.last {
    margin-bottom: 15px !important;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-sidebar {
    z-index: 10000;
    width: 50px;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-sidebar .selected {
    display: none;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-content {
    margin-left: 35px;
  }
  .page-sidebar-fixed.page-sidebar-hover-on .footer {
    margin-left: 35px;
  }

  .page-sidebar-fixed
    .page-sidebar-closed
    .page-sidebar
    .sidebar-search
    .submit,
  .page-sidebar-fixed .page-sidebar .sidebar-toggler {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .page-sidebar-hovering {
    overflow: hidden !important;
  }

  .page-sidebar-hovering .sub-menu,
  .page-sidebar-hovering span.title,
  .page-sidebar-hovering span.arrow {
    display: none !important;
  }

  .page-sidebar-hovering .submit {
    opacity: 0;
    width: 0 !important;
    height: 0 !important;
  }

  /***
	Page content
	***/
  .page-content {
    margin-left: 250px;
    margin-top: 0px;
    min-height: 100%;
  }

  .page-sidebar-fixed .page-content {
    min-height: 600px;
  }

  .page-content.no-min-height {
    min-height: auto;
  }
}

/***
For tablets and phones
***/
@media (max-width: 979px) {
  /***
	Body
	***/
  body {
    margin: 0px !important;
  }

  /***
	Page header
	***/
  .header {
    display: block !important;
    margin: 0 !important;
  }

  .header .nav li.dropdown i {
    display: inline-block;
    position: relative;
    top: 1px;
    right: 0px;
  }

  .header .nav {
    margin-bottom: 0px !important;
  }

  .header .brand {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }

  .header .brand img {
    margin-left: 2px !important;
  }

  /***
	Page container
	***/
  .page-container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .page-header-fixed .page-container {
    margin-top: 0px !important;
  }

  /***
	Page content
	***/
  .page-content {
    padding: 0 35px 35px 35px !important;
    min-height: 100%;
  }

  /***
	Page sidebar
	***/
  .page-sidebar {
    margin: 0 10px;
  }

  .page-sidebar.in {
    margin: 0px;
    position: relative;
    z-index: 5;
  }

  .page-sidebar .sidebar-toggler {
    display: none;
  }

  .page-sidebar ul {
    margin-top: 0px;
    width: 100%;
  }

  .page-sidebar .selected {
    display: none !important;
  }

  .page-sidebar .sidebar-search {
    width: 220px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-fluid {
    padding-right: 0px !important;
  }
  .row-fluid .span-69 {
    width: 100% !important;
  }
  .row-fluid .span-27 {
    clear: left;
    float: left;
    width: 100% !important;
    height: 1300px !important;
    border: 1px solid rgb(205, 205, 205);
  }

  .grid-padding {
    padding-right: 35px !important;
  }
  /***
	Page title
	***/
  .page-title {
    margin: 15px 0px;
  }

  /***
	Styler panel
	***/
  .styler-panel {
    top: 55px;
    right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  /***
	Form wizard
	***/
  .form-wizard .step .desc {
    margin-top: 10px;
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  /***
	Body
	***/
  body {
    padding-top: 0px;
  }

  /***
	Page sidebar
	***/
  .page-sidebar .btn-navbar.collapsed .arrow {
    display: none;
  }

  .page-sidebar .btn-navbar .arrow {
    position: absolute;
    right: 25px;
    width: 0;
    height: 0;
    top: 50px;
    border-bottom: 15px solid #5f646b;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}

@media (max-width: 767px) {
  .span4 {
    float: left !important;
  }
  .profile-top-btn {
    margin-top: 0px;
  }
  .padding-right {
    padding-right: 0 !important;
  }
  .container-fluid {
    margin-right: 30px;
  }
  .pad-left-10 {
    padding-left: 0 !important;
  }
  .green-check {
    padding: 5px 0 20px 0 !important;
  }
  /***
	Page header
	***/
  .header .navbar-inner .container-fluid,
  .header .navbar-inner .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .header .top-nav .nav {
    margin-top: 0px;
    margin-right: 5px;
  }

  .header .nav > li > .dropdown-menu.notification:after,
  .header .nav > li > .dropdown-menu.notification:before {
    margin-right: 180px;
  }

  .header .nav > li > .dropdown-menu.notification {
    margin-right: -180px;
  }

  .header .nav > li > .dropdown-menu.inbox:after,
  .header .nav > li > .dropdown-menu.inbox:before {
    margin-right: 140px;
  }

  .header .nav > li > .dropdown-menu.inbox {
    margin-right: -140px;
  }

  .header .nav > li > .dropdown-menu.tasks:after,
  .header .nav > li > .dropdown-menu.tasks:before {
    margin-right: 90px;
  }

  .header .nav > li > .dropdown-menu.tasks {
    margin-right: -90px;
  }

  /* Header logo */
  .header .brand {
    margin-left: 0px !important;
    width: 110px;
  }
  .btn-top {
    margin-top: 15px !important;
    /* float: left !important; */
    margin-left: -5px;
  }

  /***
	Page content
	***/

  /***
	Page title
	***/
  .page-title {
    margin-bottom: 20px;
  }

  /***
	Form input validation states
	***/
  .input-icon .input-error,
  .input-icon .input-warning,
  .input-icon .input-success {
    top: -27px;
    float: right;
    right: 10px !important;
  }

  /***
	Advance tables
	***/
  .table-advance tr td.highlight:first-child a {
    margin-left: 8px;
  }

  /***
	Vertical inline menu
	***/
  .ver-inline-menu li.active:after {
    display: none;
  }

  /***
	Form controls
	***/
  .form-horizontal .form-actions {
    padding-left: 180px;
  }
  hr.progress-head {
    margin: 30px 0 15px 0 !important;
  }
}

@media (max-width: 480px) {
  .input-append {
    width: 140px;
  }
  /***
	Header navbar
	***/
  .header .nav {
    clear: both !important;
  }

  .header .nav > li.dropdown .dropdown-toggle {
    margin-top: 3px !important;
  }

  .header .nav li.dropdown .dropdown-toggle .badge {
    top: 11px;
  }

  /***
	Page sidebar
	***/
  .page-sidebar.in {
    margin-top: 7px !important;
  }

  /***
	Styler panel
	***/
  .styler-panel {
    top: 92px;
    right: 12px;
  }

  /***
	Page title
	***/
  .page-title small {
    display: block;
    clear: both;
  }

  /***
	Dashboard date range panel
	***/
  .page-content .breadcrumb .dashboard-date-range {
    padding-bottom: 8px;
  }

  .page-content .breadcrumb .dashboard-date-range span {
    display: none;
  }

  /***
	Form controls
	***/
  .form-horizontal.form-bordered .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
    margin-top: 0;
    margin-left: 10px;
  }

  .form-horizontal.form-bordered .controls {
    padding-top: 0 !important;
    border-left: 0 !important;
  }

  .form-horizontal.form-bordered.form-label-stripped
    .control-group:nth-child(even) {
    background-color: none !important;
  }

  .form-horizontal.form-bordered.form-label-stripped
    .control-group:nth-child(even)
    .controls {
    background-color: none !important;
  }

  .form-horizontal.form-row-seperated .control-label {
    float: none;
    width: auto;
    padding-top: 0;
    text-align: left;
    margin-left: 10px;
  }

  .form-horizontal.form-row-seperated .controls {
    border-left: 0;
    margin-left: 10px;
  }

  /***
	Hidden
	***/
  .hidden-480 {
    display: none;
  }

  /***
	Modal header close button fix
	***/
  .modal-header .close {
    margin-top: 5px !important;
  }

  /***
	Fix text view
	***/
  .control-group .controls .text {
    display: block !important;
    margin-bottom: 10px;
  }
  input.onboarding {
    max-width: 93% !important;
  }
}

@media (max-width: 1200px) {
  .modal,
  .modal.container,
  .modal.modal-overflow {
    top: 20% !important;
    right: 10% !important;
    left: 10% !important;
  }
}

@media (max-width: 640px) {
  .modal,
  .modal.container,
  .modal.modal-overflow,
  .modal-backdrop {
    display: none !important;
  }
}

@media (max-width: 320px) {
  .header .nav > li.dropdown .dropdown-toggle {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  /***
	Hidden phone
	***/
  .hidden-320 {
    display: none;
  }

  .header .brand {
    width: 100px;
  }
}
