@font-face {
    font-family: 'droid_serifregular';
    src: url('DroidSerif-Regular-webfont.eot');
    src: url('DroidSerif-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('DroidSerif-Regular-webfont.woff') format('woff'),
         url('DroidSerif-Regular-webfont.ttf') format('truetype'),
         url('DroidSerif-Regular-webfont.svg#droid_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sanssemibold';
    src: url('OpenSans-Semibold-webfont.eot');
    src: url('OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-Semibold-webfont.woff') format('woff'),
         url('OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sansregular';
    src: url('OpenSans-Regular-webfont.eot');
    src: url('OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-Regular-webfont.woff') format('woff'),
         url('OpenSans-Regular-webfont.ttf') format('truetype'),
         url('OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sanssemibold_italic';
    src: url('OpenSans-SemiboldItalic-webfont.eot');
    src: url('OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'open_sansbold';
    src: url('OpenSans-Bold-webfont.eot');
    src: url('OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-Bold-webfont.woff') format('woff'),
         url('OpenSans-Bold-webfont.ttf') format('truetype'),
         url('OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'droid_serifitalic';
    src: url('DroidSerif-Italic-webfont.eot');
    src: url('DroidSerif-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('DroidSerif-Italic-webfont.woff') format('woff'),
         url('DroidSerif-Italic-webfont.ttf') format('truetype'),
         url('DroidSerif-Italic-webfont.svg#droid_serifitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




