/* You can add global styles to this file, and also import other style files */
@import 'node_modules/ng-busy/src/style/busy.css';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import 'assets/fonts/font.css';

@import 'style.scss';
@import 'responsive.scss';
@import 'default.scss';
@import 'form.scss';
