html {
  font-size: 14px;
}

body {
  color: #1a1a1a;
  font-family: open_sansregular;
  direction: ltr;
  margin: 0 !important;
  padding: 0 !important;
}

h3 small,
h4 small,
h5 small {
  color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-bottom: 10px;
}

.bold {
  font-family: 'open_sanssemibold';
}

.italic {
  font-style: italic;
}

a {
  cursor: pointer;
}

a,
td a i {
  text-shadow: none !important;
  color: #0d638f;
}

td a i {
  color: #0d638f !important;
}

td a:hover i {
  color: black;
}

[class^='icon-'],
[class*=' icon-'],
[class^='icon-']:hover,
[class*=' icon-']:hover {
  background: none !important;
}

li [class^='icon-'],
li [class*=' icon-'] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

li [class^='icon-'].icon-large,
li [class*=' icon-'].icon-large {
  width: 1.5625em;
}

ul.intro-sub-list {
  list-style: none;
}

ul.intro-sub-list li:before {
  content: '\2666 \0020';
}

label {
  cursor: default;
}

.close {
  display: inline-block;
  margin-top: 0;
  margin-right: 0;
  width: 19px;
  height: 19px;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
}
.close-red {
  background-image: url(assets/img/remove-icon-small.png) !important;
}
.close-yellow {
  background-image: url(assets/img/remove-icon-small-yellow.png) !important;
  background-size: auto;
}
.close-green {
  background-image: url(assets/img/remove-icon-small-green.png) !important;
}
.ie8 .row-fluid .span-69 {
  width: 69.43%;
}

.ie8 .row-fluid .span-27 {
  width: 27.8%;
}

.ie8 .row-fluid .span-100 {
  width: 100%;
}

a,
a:hover,
a:active {
  outline: 0;
}

p#agency-support-list span {
  padding-right: 1em;
}

p#agency-support-list a.selected-link {
  border-style: solid;
  border-width: 0.1em;
  border-color: #0099cc;
  padding: 0.2em;
  outline: none;
}

.ie8 .close {
  filter: alpha(opacity=100) !important;
  background: #fff;
}

.ie8 .row-fluid [class*='span'] {
  min-height: 20px !important;
}

.ie8 .row {
  margin-left: 0;
}

.bold {
  font-weight: 600 !important;
}

.border {
  border: 1px solid red;
}

hr {
  border: 0;
  border-top: 1px solid #333;
  border-bottom: 1px solid #fefefe;
}

.header {
  margin: 0 !important;
  padding: 0 !important;
}

.header.navbar-fixed-top {
  z-index: 9995 !important;
}

.header .brand {
  display: inline-block;
  margin-top: -1px;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 225px;
}

.header .brand img {
  margin-left: 20px;
}

.header .btn-navbar {
  margin-bottom: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 6px;
  background-image: none;
  filter: none;
  box-shadow: none;
  color: #fff;
  border: 0;
}

/* JDG TO DO: replace </button>&nbsp hacks with desired spacing between buttons. For now, limit to open ticket. */
#assign-reports-toolbar.toolbar .btn {
  margin-right: 0.3em;
}

.header .navbar-inner {
  width: 100%;
  margin-left: 0 0 0 110px;
  border: 0;
  box-shadow: none;
  height: 42px;
  padding: 0;
}

.header .nav > li.dropdown,
.header .nav > li.dropdown > a {
  padding-left: 4px;
  padding-right: 4px;
}

.header .nav > li.dropdown > a:last-child {
  padding-right: 0;
}

.header .nav > li.dropdown:last-child {
  padding-right: 2px;
}

.header .nav > li.dropdown .dropdown-toggle {
  margin: 0;
  padding: 15px 10px 7px;
}

.header .nav > li.dropdown .dropdown-toggle i {
  font-size: 18px;
}

.header .nav > li.dropdown.user .dropdown-toggle {
  padding: 6px 4px 7px 9px;
}

.header .nav > li.dropdown.user .dropdown-toggle .username {
  color: #ddd;
}

.header .nav li.dropdown.user .dropdown-toggle i {
  display: inline-block;
  font-size: 16px;
  margin: 0;
}

.header .nav > li.dropdown.user .dropdown-menu i {
  width: 15px;
  display: inline-block;
}

.header .nav > li.dropdown .dropdown-toggle .badge {
  position: absolute;
  font-size: 11px !important;
  font-weight: 300;
  top: 8px;
  right: 24px;
  text-align: center;
  height: 14px;
  background-color: #e02222;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  padding: 2px 6px;
}

.header .nav .dropdown-menu {
  margin-top: 3px;
}

.page-header-fixed .page-container {
  margin-top: 42px;
}

.ie8 .page-sidebar {
  position: absolute;
  width: 250px;
}

ul.page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.page-sidebar-menu > li {
  display: block;
  border: 0;
  background: #15354e;
  margin: 0;
  padding: 0;
}

ul.page-sidebar-menu > li.start > a {
  border-top: 1px solid #314a59 !important;
  background: #15354e;
}

ul.page-sidebar-menu > li:last-child > a,
ul.page-sidebar-menu > li.last > a {
  border-bottom-color: transparent !important;
  background: #15354e;
}

ul.page-sidebar-menu > li > a {
  display: block;
  position: relative;
  border: 0;
  text-decoration: none;
  font-size: 12px;
  font-weight: 200;
  margin: 0;
  padding: 9px 12px;
}

ul.page-sidebar-menu > li > a i {
  font-size: 14px;
  /*font-weight: 200; doesn't work with font-awesome 5? */
  margin-right: 5px;
  text-shadow: none;
}

ul.page-sidebar-menu > li > span {
  display: block;
  position: relative;
  border-top: 1px solid #314a59 !important;
  border-bottom: 1px solid #314a59 !important;
  text-decoration: none;
  font-size: 12px;
  font-weight: 200;
  margin: 0;
  padding: 9px 12px;
}

ul.page-sidebar-menu > li > span i {
  font-size: 14px;
  margin-right: 7px;
  margin-left: 1.5px;
  text-shadow: none;
}

ul.page-sidebar-menu > li.break {
  margin-bottom: 20px;
}

ul.page-sidebar-menu > li.active > a {
  border: none;
  text-shadow: none;
}

ul.page-sidebar-menu > li.active > a .selected {
  display: block;
  width: 8px;
  height: 25px;
  float: right;
  position: absolute;
  right: 0;
  top: 8px;
}

.page-sidebar ul > li > a > .arrow:before {
  float: right;
  margin-top: 1px;
  margin-right: 5px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: '\f105';
  font-weight: 300;
  text-shadow: none;
}

ul.page-sidebar-menu > li > a > .arrow.open:before {
  float: right;
  margin-top: 1px;
  margin-right: 3px;
  display: inline;
  font-family: FontAwesome;
  height: auto;
  font-size: 16px;
  content: '\f107';
  font-weight: 300;
  text-shadow: none;
}

ul.page-sidebar-menu > li > ul.sub-menu {
  background-color: #112c3f;
  display: none;
  list-style: none;
  clear: both;
  margin: 0;
  padding: 15px 0;
  border-bottom: 1px solid #324b5a;
}

ul.page-sidebar-menu > li.active > ul.sub-menu {
  display: block;
  background-color: #112c3f;
}

ul.page-sidebar-menu > li > ul.sub-menu > li {
  background: none;
  margin: 1px 0 0 !important;
  padding: 2px 0 10px;
}

ul.page-sidebar-menu > li > ul.sub-menu > li > a {
  display: block;
  padding-left: 40px !important;
  text-decoration: none;
  font-size: 12px;
  font-weight: 200;
  background: none;
}

ul.page-sidebar-menu > li > ul.sub-menu > li ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 0;
}

ul.page-sidebar-menu > li > ul.sub-menu li > a > .arrow:before {
  float: right;
  margin-top: 1px;
  margin-right: 20px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: '\f104';
  font-weight: 300;
  text-shadow: none;
}

ul.page-sidebar-menu > li > ul.sub-menu li > a > .arrow.open:before {
  float: right;
  margin-top: 1px;
  margin-right: 18px;
  display: inline;
  font-family: FontAwesome;
  height: auto;
  font-size: 16px;
  content: '\f107';
  font-weight: 300;
  text-shadow: none;
}

ul.page-sidebar-menu > li > ul.sub-menu > li ul.sub-menu li {
  background: none;
  margin: 0 !important;
  padding: 0;
}

ul.page-sidebar-menu > li > ul.sub-menu li > ul.sub-menu > li > a {
  display: block;
  text-decoration: none;
  font-weight: 300;
  background: none;
  margin: 0;
  padding: 5px 0;
}

ul.page-sidebar-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  padding-left: 60px;
}

ul.page-sidebar-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a {
  padding-left: 80px;
}

.page-sidebar .sidebar-search {
  margin: 8px 20px 20px;
}

.page-sidebar .sidebar-search .submit {
  display: block;
  float: right;
  margin-top: 8px;
  width: 13px;
  height: 13px;
  //background-image: url(assets/img/search-icon.png);
  background-repeat: no-repeat;
}

.page-sidebar .sidebar-search input {
  width: 165px;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  font-size: 14px;
  box-shadow: none;
  margin: 0;
}

.page-sidebar .sidebar-search .input-box {
  padding-bottom: 2px;
}

.page-sidebar .sidebar-toggler {
  cursor: pointer;
  opacity: 1;
  margin-top: 0;
  margin-left: 210px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
}

.sidebar-toggler:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}

.page-sidebar-closed .sidebar-toggler {
  margin-left: 0;
  margin-right: 3px;
}

.page-sidebar-closed .page-sidebar .sidebar-search {
  height: 34px;
  width: 40px;
  margin-left: 3px;
  margin-bottom: 15px !important;
  margin-top: 6px;
}

.page-sidebar-closed .page-sidebar .sidebar-search .submit {
  display: block !important;
  margin: 11px 7px !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search .input-box {
  border-bottom: 0 !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open {
  height: 34px;
  width: 255px;
  overflow: hidden;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open input {
  margin-top: 3px;
  padding-left: 10px;
  padding-bottom: 2px;
  width: 180px;
  display: inline-block !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .submit {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 10px 8px 9px 6px !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .remove {
  background-repeat: no-repeat;
  width: 11px;
  height: 11px;
  display: inline-block !important;
  float: left !important;
  margin: 11px 6px 7px 8px !important;
}

.page-sidebar-closed ul.page-sidebar-menu > li > a .selected {
  right: -3px !important;
}

.page-sidebar-closed ul.page-sidebar-menu {
  width: 40px !important;
}

.page-sidebar-closed ul.page-sidebar-menu > li > a {
  padding-left: 7px;
}

.page-sidebar-closed ul.page-sidebar-menu > li:hover {
  width: 260px !important;
  position: relative !important;
  z-index: 2000;
  display: block !important;
}

.page-sidebar-closed ul.page-sidebar-menu > li.no-hover:hover {
  width: 40px !important;
  position: relative !important;
  z-index: 2000;
  display: block !important;
}

.page-sidebar-closed ul.page-sidebar-menu > li:hover > a > i {
  margin-right: 10px;
}

.page-sidebar-closed ul.page-sidebar-menu > li:hover .title {
  display: inline !important;
}

.page-sidebar-closed ul.page-sidebar-menu > li:hover > .sub-menu {
  width: 220px;
  position: absolute;
  z-index: 2000;
  left: 40px;
  margin-top: 0;
  top: 100%;
  display: block !important;
}

.page-sidebar-closed
  ul.page-sidebar-menu
  > li:hover
  > .sub-menu
  > li
  > .sub-menu,
.page-sidebar-closed
  ul.page-sidebar-menu
  > li:hover
  > .sub-menu
  > li
  > .sub-menu
  > li
  > .sub-menu {
  width: 189px;
}

.page-sidebar-closed ul.page-sidebar-menu > li:hover > .sub-menu > li > a {
  padding-left: 15px !important;
}

.page-sidebar-closed
  ul.page-sidebar-menu
  > li
  > ul.sub-menu
  > li
  > .sub-menu
  > li
  > a {
  padding-left: 30px !important;
}

.page-sidebar-closed
  ul.page-sidebar-menu
  > li
  > ul.sub-menu
  > li
  > .sub-menu
  > li
  > .sub-menu
  > li
  > a {
  padding-left: 45px !important;
}

.page-sidebar-closed .page-sidebar {
  width: 40px;
}

.page-sidebar-closed .page-content {
  margin-left: 40px !important;
}

.page-content {
  margin-top: 0;
  background-color: #fff;
  padding: 0;
}

.ie8 .page-content {
  margin-left: 250px;
  margin-top: 0;
  min-height: 760px;
}

.ie8 .page-sidebar-fixed .page-content {
  min-height: 600px;
}

.ie8 .page-content.no-min-height {
  min-height: auto;
}

.page-title {
  font-size: 30px;
  letter-spacing: -1px;
  display: block;
  color: #666;
  font-weight: 300;
  font-family: open_sansregular;
  margin: 20px 0 15px;
  padding: 0;
}

.page-title small {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 300;
  color: #888;
}

.page-content .breadcrumb {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  padding-right: 0;
  padding-left: 8px;
  margin-bottom: 25px;
  border: 0 !important;
  background-color: #eee;
}

.page-content .breadcrumb a,
.page-content .breadcrumb i,
.page-content .breadcrumb span {
  color: #333;
  font-size: 14px;
  text-shadow: none;
}

.page-content .breadcrumb i {
  color: #666;
}

.page-content .breadcrumb .dashboard-date-range {
  display: none;
  margin-right: 0;
  margin-top: -8px;
  cursor: pointer;
  color: #fff;
  background-color: #e02222;
  padding: 8px 8px 7px;
}

.page-content .breadcrumb .dashboard-date-range span {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
}

.page-content .breadcrumb .dashboard-date-range .icon-calendar {
  text-transform: normal;
  color: #fff;
  margin-top: 0;
  font-size: 14px;
}

.page-content .breadcrumb .dashboard-date-range .icon-angle-down {
  color: #fff;
  font-size: 16px;
}

i.icon,
a.icon {
  color: #999;
  margin-right: 5px;
  font-weight: 400;
  font-size: 13px;
}

i.icon-black {
  color: #000 !important;
}

a.icon:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: 1;
  filter: alpha(opacity=100);
}

a.icon.huge i {
  font-size: 16px !important;
}

i.big {
  font-size: 20px;
}

i.warning {
  color: #d12610;
}

i.critical {
  color: #37b7f3;
}

i.normal {
  color: #52e136;
}

.well {
  background-color: #fafafa;
  border: 1px solid #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.well.mini {
  padding: 7px !important;
}

.form-section {
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
  margin: 15px 0 20px !important;
}

.form .form-actions {
  margin-top: 0 !important;
  padding-left: 190px;
}

.dropdown-menu.extended {
  min-width: 160px !important;
  max-width: 300px !important;
  width: 233px !important;
  background-color: #fff !important;
}

.dropdown-menu.extended:before,
.dropdown-menu.extended:after {
  border-bottom-color: #ddd !important;
}

.dropdown-menu.extended li a {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 20px;
  white-space: normal !important;
  background-color: #fff;
  list-style: none;
  border-bottom: 1px solid #f4f4f4 !important;
  font-size: 12px;
  text-shadow: none;
  padding: 10px !important;
}

.dropdown-menu.extended li i {
  margin-right: 3px;
}

.dropdown-menu.extended li a:hover {
  background-image: none;
  background-color: #f5f5f5;
  color: #000;
  filter: none;
}

.dropdown-menu.extended li p {
  background-color: #eee;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  margin: 0;
  padding: 10px;
}

.dropdown-menu.extended li:first-child a {
  border-top: none;
  border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu.extended li:last-child a {
  border-top: 1px solid #fff !important;
  border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu.extended li.external > a {
  font-size: 13px;
  font-weight: 400;
}

.dropdown-menu.extended li.external > a > i {
  margin-top: 3px;
  float: right;
}

.dropdown-menu.notification li > a > .label {
  width: 10px;
  margin-right: 2px;
  text-align: center !important;
  padding: 2px 4px;
}

.dropdown-menu.notification li > a > .label i {
  text-align: center !important;
}

.dropdown-menu.notification li a .time {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  text-align: right;
}

.dropdown-menu.inbox li a .photo img {
  float: left;
  height: 40px;
  width: 40px;
  margin-right: 6px;
}

.dropdown-menu.inbox li a .subject .from {
  font-size: 14px;
  font-weight: 400;
  color: #02689b;
}

.dropdown-menu.inbox li a .subject .time {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  position: absolute;
  right: 10px;
}

.dropdown-menu.inbox li a .message {
  display: block !important;
  font-size: 12px;
}

.dropdown-menu.tasks .task {
  margin-bottom: 5px;
}

.dropdown-menu.tasks .task .desc {
  font-size: 13px;
  font-weight: 300;
}

.dropdown-menu.tasks .task .percent {
  font-size: 13px;
  font-weight: 600;
  float: right;
  display: inline-block;
}

.dropdown-menu.tasks .progress {
  display: block;
  height: 10px;
  margin: 0;
}

.item-list li .img {
  height: 50px;
  width: 50px;
  float: left;
  margin-top: 3px;
  margin-right: 5px;
}

.item-list li {
  list-style: none;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  padding: 7px 0 5px;
}

.item-list li:first-child {
  border-top: none;
  border-bottom: 1px solid #ebebeb;
}

.item-list li:last-child {
  border-top: none;
  border-bottom: none;
}

.item-list li .label {
  margin-right: 5px;
}

.item-list.todo li .label {
  position: absolute;
  right: 80px;
}

.item-list.todo li .actions {
  position: absolute;
  right: 45px;
}

.table.table-full-width {
  width: 100% !important;
}

.table .m-btn {
  margin-top: 0;
  margin-left: 0;
  margin-right: 5px;
}

.table thead tr th {
  font-size: 11px;
  font-weight: 300;
  color: #ecc256;
  background-color: #133550;
  text-transform: uppercase;
}

.table tr:last-child {
  border-bottom: 1px solid #ddd;
}

.table-advance thead tr th {
  background-color: #ddd;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
  position: absolute;
  margin-top: -5px;
  float: left;
  width: 2px;
  height: 30px;
  margin-right: 20px !important;
}

.table-advance tr td {
  border-left-width: 0;
}

.table-advance tr td:first-child {
  border-left-width: 1px !important;
}

.table-advance tr td.highlight:first-child a {
  margin-left: 15px;
}

.table-advance td.highlight div.success {
  border-left: 2px solid #6e6;
}

.table-advance td.highlight div.info {
  border-left: 2px solid #87ceeb;
}

.table-advance td.highlight div.important {
  border-left: 2px solid #f02c71;
}

.table-advance td.highlight div.warning {
  border-left: 2px solid #fdbb39;
}

.table-advance td.highlight div.danger {
  border-left: 2px solid #e23e29;
}

.checkbox .checker {
  margin-top: -2px !important;
  margin-right: 2px !important;
}

.controls .text {
  display: block;
  margin-top: 7px;
  font-weight: 400;
  font-size: 14px;
}

.controls .text-inline {
  display: inline-block;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
}

.controls-uniform {
  margin-top: -7px;
}

input.placeholder,
textarea.placeholder {
  color: #aaa !important;
}

.help-block {
  margin-top: 0;
}

.form-inline input {
  margin-bottom: 0 !important;
  width: 66% !important;
}
.form-inline input[type='checkbox'] {
  margin-bottom: 0 !important;
  width: 18px !important;
}
.control-label {
  margin-top: 2px;
}

.control-label .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

.validate-inline {
  display: inline;
  padding-left: 5px;
  vertical-align: middle;
  zoom: 1;
  margin-top: 6px;
}

.control-group.info .validate-inline {
  color: #3a87ad;
}

.control-group.error .validate-inline {
  color: #b94a48;
}

.control-group.warning .validate-inline {
  color: #c09853;
}

.help-inline {
  display: inline-block;
  font-family: droid_serifitalic;
  font-style: normal;
}
.help-inline.valid.ok {
  display: none !important;
}
.help-small {
  font-size: 12px;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.success .validate-inline.ok:before,
.success .help-inline.ok:before {
  content: '\f00c';
  font-size: 16px;
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  display: inline-block;
}

.controls > .radio .checker,
.controls > .checkbox .checker {
  margin-right: 2px !important;
}

.controls > .radio,
.controls > .checkbox {
  display: inline-block;
  margin: 8px 15px 0 0 !important;
  padding: 0 !important;
}

.controls > .radio.line,
.controls > .checkbox.line {
  display: block;
  margin: 5px 0 0 !important;
  padding: 0 !important;
}

.form-view .control-group {
  margin-top: 0;
  margin-bottom: 5px;
}

.uploader {
  margin-top: 2px !important;
}

.tab-pane.listless {
  padding: 0 20px;
}

.tab-pane.treeful {
  padding-right: 20px;
}

.tab-content {
  overflow: hidden;
  padding: 0;
}

.tabbable-custom {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin-bottom: 15px;
}

.tabbable-custom > .nav-tabs {
  border: none;
  letter-spacing: 0.08em;
  margin: 0;
}

.tabbable-custom > .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 10px;
}

.tabbable-custom.boxless > .tab-content {
  border-left: 1px solid #d4d4d4 transparent;
  border-right: 1px solid #d4d4d4 transparent;
  border-bottom: 1px solid #d4d4d4 transparent;
  padding: 20px 0;
}

.tabbable-custom.floating .nav-tabs {
  border-left: none !important;
  border-right: none !important;
}

.tabbable-custom .nav-tabs > li {
  font-size: 11px !important;
  font-weight: 500;
  margin-top: 2px;
  margin-right: 7px;
  border-top: 2px solid transparent;
}

.tabbable-custom .nav-tabs > li > a {
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
  background-color: #e2e2e2;
  border-bottom: none;
  padding: 9px 15px;
}

.tabbable-custom .nav-tabs > li.active > a {
  background-color: #fff;
  border-bottom: 1px solid #fff !important;
  color: #000;
}

.tabs-below.tabbable-custom .nav-tabs > li {
  border-top: none;
  border-bottom: 2px solid transparent;
  margin-top: -1px;
}

.tabs-below.tabbable-custom .nav-tabs > li.active {
  border-top: none;
  border-bottom: 3px solid #d12610;
  margin-bottom: 0;
  position: relative;
}

.tabs-below.tabbable-custom .nav-tabs > li.active > a {
  border-bottom: none;
}

.tabs-left.tabbable-custom .nav-tabs > li {
  margin-right: 0;
  border-left: 2px solid transparent;
  margin-top: none;
}

.tabs-left.tabbable-custom .nav-tabs > li.active {
  border-top: none;
  border-left: 3px solid #d12610;
  margin-top: 0;
  margin-right: -1px;
  position: relative;
}

.tabs-left.tabbable-custom .nav-tabs > li.active > a {
  border-top: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
}

.tabs-right.tabbable-custom .nav-tabs > li {
  margin-right: 0;
  border-right: 1px solid transparent;
  border-top: none;
}

.tabs-right.tabbable-custom .nav-tabs > li.active {
  border-top: none;
  border-right: 3px solid #d12610;
  margin-top: 0;
  margin-left: -1px;
  position: relative;
}

.tabs-right.tabbable-custom .nav-tabs > li.active > a {
  border-top: 1px solid #d4d4d4;
  border-right: 1px solid transparent;
}

.tabs-right.tabbable-custom .nav-tabs > li a,
.tabs-left.tabbable-custom .nav-tabs > li a {
  padding: 8px 10px;
}

.tabbable-custom.tabbable-full-width > .tab-content {
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 27px 0;
}

.tabbable-custom.tabbable-full-width .nav-tabs > li > a {
  color: #424242;
  font-size: 15px;
  padding: 9px 15px;
}

.form-wizard .progress {
  margin-bottom: 30px;
}

.form-wizard .steps {
  margin-bottom: 15px;
  padding: 10px 0;
}

.form-wizard .step .number {
  background-color: #eee;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  margin-right: 10px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
  padding: 12px 15px !important;
}

.form-wizard .step .desc {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
}

.form-wizard .active .step .number {
  background-color: #35aa47;
  color: #fff;
}

.form-wizard .done .step .number {
  background-color: #f2ae43;
  color: #fff;
}

.form-wizard .done .step i {
  font-size: 12px;
  font-weight: 400;
  color: #999;
  display: inline-block;
}

.form-wizard .tab-pane .chzn-container {
  position: absolute !important;
}

.scroller {
  margin: 0 !important;
  padding: 5px 15px 0 15px !important;
}

.portlet-body .slimScrollBar {
  margin-right: 0 !important;
}

.error .select2-container .select2-choice {
  border-color: #b94a48;
}

.success .select2-container .select2-choice {
  border-color: #468847;
}

.cke_bottom,
.cke_inner,
.cke_top,
.cke_reset,
.cke_dialog_title,
.cke_dialog_footer,
.cke_dialog {
  background-image: none !important;
  filter: none !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

.cke_dialog_ui_button,
.cke_dialog_tab {
  background-image: none !important;
  filter: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

.cke_dialog_ui_button:hover,
.cke_dialog_tab:hover {
  text-decoration: none;
  text-shadow: none !important;
}

.cke_dialog_ui_input_text {
  background-image: none !important;
  filter: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.cke_combo_button,
.cke_button,
.cke_toolbar,
.cke_toolgroup {
  background-image: none !important;
  filter: none !important;
  border: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_panel_grouptitle,
.cke_hc.cke_panel_listItem a {
  background-image: none !important;
  filter: none !important;
  text-shadow: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.cke_button:hover,
.cke_combo_button:hover {
  background-color: #ddd;
}

.cke_toolbar_break {
  background-image: none !important;
  filter: none !important;
  border: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
}

.carousel-inner .item {
  line-height: 20px;
}

.glyphicons-demo {
  text-align: center;
}

.glyphicons-demo .glyphicons {
  display: inline;
  zoom: 1;
  width: 150px;
  font-size: 14px;
  line-height: 48px;
  margin-right: 20px;
  color: #999;
  text-align: left;
}

.glyphicons-demo .glyphicons i:before {
  line-height: 55px !important;
  color: #666;
}

.halfings-demo .white-content {
  background: rgba(0, 0, 0, 0.9);
  margin: 0 -20px;
  padding: 20px;
}

.halfings-demo h2 {
  text-align: left;
}

.halfings-demo p,
.halfings-demo a {
  width: 175px;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  margin-right: 10px;
}

.halfings-demo .halflings,
.halfings-demo .halflings-icon {
  margin: 0 5px 0 0;
}

.table.sliders td {
  padding: 15px 10px !important;
}

.table.sliders .slider {
  margin-top: 4px;
}

.table.sliders .slider-value {
  padding-top: 5px;
  font-weight: 400;
}

.table.sliders .slider-vertical-value {
  padding-bottom: 5px;
  font-weight: 400;
}

.slider {
  border: 0 !important;
}

.ui-slider-vertical,
.ui-slider-handle {
  filter: none !important;
  background-image: none !important;
}

.slider-eq > span {
  height: 125px;
  float: left;
  margin: 15px;
}

.tree a {
  /* display: block!important; */
  line-height: 16px !important;
  padding: 2px 3px 3px;
}

.tree a:hover {
  text-decoration: none;
  /* background-color: #eee */
}

.tree a.tree-toggle-icon-only,
.tree a.tree-toggle {
  background-position: 2px -19px;
}

.tree a.tree-toggle.closed,
.tree a.tree-toggle-icon-only.closed {
  background-position: 2px 4px;
}

.modal-body .tree a.tree-toggle-icon-only,
.modal-body .tree a.tree-toggle {
  background-position: 2px -21px;
}

.modal-body .tree a.tree-toggle.closed,
.modal-body .tree a.tree-toggle-icon-only.closed {
  background-position: 2px 2px;
}

.ui-dialog {
  z-index: 10011 !important;
  background: none !important;
  background-color: #eee !important;
  margin-left: 40px;
  margin-right: 40px;
  max-width: 500px;
}

.ui-dialog .ui-dialog-titlebar {
  font-weight: 300 !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: #333 !important;
  background: none !important;
  background-color: #e0e0e0 !important;
  border: 0 !important;
  box-shadow: none !important;
  font-family: open_sansregular;
}

.ui-dialog .ui-dialog-titlebar-close {
  outline: none !important;
  border: 0 !important;
  box-shadow: none;
  //background: url(assets/img/hor-menu-search-close.png) no-repeat center;
  margin-top: -12px !important;
}

.ui-dialog.ui-dialog-blue .ui-dialog-titlebar {
  color: #fff !important;
  background-color: #4b8df8 !important;
}

.ui-dialog.ui-dialog-red .ui-dialog-titlebar {
  color: #fff !important;
  background-color: #e02222 !important;
}

.ui-dialog.ui-dialog-yellow .ui-dialog-titlebar {
  color: #fff !important;
  background-color: #ffb848 !important;
}

.ui-dialog.ui-dialog-green .ui-dialog-titlebar {
  color: #fff !important;
  background-color: #35aa47 !important;
}

.ui-dialog.ui-dialog-purple .ui-dialog-titlebar {
  color: #fff !important;
  background-color: #852b99 !important;
}

.ui-dialog.ui-dialog-grey .ui-dialog-titlebar {
  color: #fff !important;
  background-color: #555 !important;
}

.ui-dialog .ui-dialog-content {
  font-family: open_sansregular;
  font-size: 13px !important;
  color: #333 !important;
}

.ui-dialog .ui-dialog-content .icon {
  display: inline-block;
  float: left;
  margin: 5px 7px 20px 0;
}

.ui-widget-overlay.ui-front {
  z-index: 10010;
  background: #333 !important;
}

.sidebar-content {
  margin-top: 20px;
}

.header .hor-menu {
  float: left;
  margin: 0;
}

.header .hor-menu ul.nav li a {
  font-size: 14px;
  padding: 11px 18px;
}

.ie8 .header .hor-menu a.dropdown-toggle {
  padding-top: 10px !important;
}

.header .hor-menu ul.nav li.active > a,
.header .hor-menu ul.nav li.active > a:hover {
  background: #e02222 !important;
}

.ie8 .header .hor-menu ul.nav li.active > a {
  padding-top: 13px;
  padding-bottom: 12px;
}

.ie9 .header .hor-menu ul.nav li.active > a {
  padding-top: 10px;
  padding-bottom: 12px;
}

.header .hor-menu ul.nav li.active .selected {
  left: 50%;
  bottom: 0;
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #e02222;
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 0 -6px -7px;
}

.header .hor-menu ul.nav li a:hover,
.header .hor-menu ul.nav li a:focus {
  background: #2d2d2d;
}

.header .hor-menu .dropdown-menu {
  margin-top: 0;
  border: none;
  box-shadow: none;
  background: #2d2d2d;
}

.header .hor-menu .dropdown-menu li > a {
  color: #999;
  margin-bottom: 1px;
  padding: 7px 18px !important;
}

.header .hor-menu ul.nav > li > a > .arrow:before {
  margin-top: 7px;
  margin-left: 3px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: '\f107';
  font-weight: 300;
  text-shadow: none;
}

.header .hor-menu ul.nav > li .dropdown-menu > li > a > .arrow:before {
  float: right;
  margin-top: 1px;
  margin-right: -5px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: '\f105';
  font-weight: 300;
  text-shadow: none;
}

.header .hor-menu .dropdown-menu li > a:hover,
.header .hor-menu .dropdown-menu li:hover > a,
.header .hor-menu .dropdown-menu li.active > a {
  color: #fff;
  filter: none !important;
  background: #e02222 !important;
}

.header .hor-menu .nav > li > .dropdown-menu:after,
.header .hor-menu .nav > li > .dropdown-menu:before {
  border-bottom: none !important;
}

.header .hor-menu .hor-menu-search-form-toggler {
  display: inline-block;
  cursor: pointer;
  // background: url(assets/img/hor-menu-search.png) no-repeat center;
  padding: 11px 22px !important;
}

.header .hor-menu .hor-menu-search-form-toggler.hide {
  //background: #101010 url(assets/img/hor-menu-search-close.png) no-repeat center;
}

.header .hor-menu .search-form {
  top: 42px;
  right: 0;
  display: none;
  z-index: 999;
  position: absolute;
  background: #101010;
  padding: 8px;
}

.header .hor-menu .search-form .btn {
  color: #999;
  height: 32px;
  width: 10px;
  display: inline-block;
  // background: #2d2d2d url(assets/img/search-icon.png) no-repeat center;
  padding: 7px 20px;
}

.header .hor-menu .search-form form input {
  color: #999;
  border: none;
}

ul.sidebar-tags a {
  color: #555;
  font-size: 12px;
  background: #f7f7f7;
  display: inline-block;
  margin: 0 2px 5px 0;
  padding: 2px 5px;
}

ul.sidebar-tags a:hover,
ul.sidebar-tags a:hover i {
  color: #fff;
  background: #555;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul.sidebar-tags a i {
  color: #777;
}

.social-icons {
  margin: 0;
}

.social-icons:after,
.social-icons:before {
  content: '';
  display: table;
}

.social-icons li {
  float: left;
  display: inline;
  list-style: none;
  margin-right: 5px;
  margin-bottom: 5px;
  text-indent: -9999px;
}

.social-icons li a,
a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.visible-ie8,
.visible-ie9,
.hide,
.page-sidebar-closed .page-sidebar .sidebar-search input,
.page-sidebar-closed ul.page-sidebar-menu > li:hover .selected,
.form-wizard .step i,
.header .dropdown-menu .arrow,
.header .hor-menu a.hor-menu-search-form-toggler-close {
  display: none;
}

.ie8 .visible-ie8,
.ie9 .visible-ie9 {
  display: inherit !important;
}

.hidden-ie8,
.hidden-ie9 {
  display: inherit;
}

.ie8 .hidden-ie8,
.ie9 .hidden-ie9,
.page-sidebar-closed ul.page-sidebar-menu > li > a > .title,
.page-sidebar-closed ul.page-sidebar-menu > li > a > .arrow,
.page-sidebar-closed ul.page-sidebar-menu > li > .sub-menu,
.page-sidebar-closed ul.page-sidebar-menu > li > span > span {
  display: none !important;
}

.ie8 img {
  width: inherit; /* This makes the next two lines work in IE8. */
  max-width: 100%; /* Add !important if needed. */
  height: auto; /* Add !important if needed. */
}

.fix-offset,
.no-left-margin,
.fix-margin,
.page-full-width .page-content {
  margin-left: 0 !important;
}

.no-space,
.form form {
  margin: 0 !important;
  padding: 0 !important;
}

.no-left-padding,
.radio,
.checkbox {
  padding-left: 0 !important;
}

.margin-bottom-10,
.table-advance,
.user-info {
  margin-bottom: 10px !important;
}

.margin-bottom-15,
form legend,
.form-view legend {
  margin-bottom: 15px !important;
}

.inline,
.blog-images li {
  display: inline;
}

.header .btn-navbar:hover,
.header .nav > li.dropdown.user .dropdown-toggle:hover,
.accordion-heading a,
.accordion-heading a:hover,
.form-wizard .step:hover,
.blog-images li a:hover {
  text-decoration: none;
}

.header .nav,
ul.page-sidebar-menu > li.active > ul.sub-menu > li.active ul.sub-menu,
ul.page-sidebar-menu
  > li.active
  > ul.sub-menu
  > li.active
  ul.sub-menu
  > li.active
  ul.sub-menu,
.dropdown-menu.inbox li a .subject,
.chats li .message .body {
  display: block;
}

.header .nav > li,
.page-container,
.tree {
  margin: 0;
  padding: 0;
}

.page-sidebar-fixed ul.page-sidebar-menu > li > a,
.page-sidebar-fixed.page-sidebar-closed ul.page-sidebar-menu > li > a {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

ul.page-sidebar-menu > li > ul.sub-menu li > ul.sub-menu > li > a > i,
.ver-inline-menu li.active a,
.ver-inline-menu li:hover a {
  font-size: 13px;
}

.input-icon .input-error,
.error .select2-container .select2-choice > span {
  color: #b94a48;
}

.input-icon .input-success,
.control-group.success .validate-inline,
.success .select2-container .select2-choice > span {
  color: #468847;
}

.icon-btn i,
.icon-btn .glyphicons i:before {
  font-size: 20px !important;
  color: #777 !important;
}

.icon-btn .glyphicons,
ul.sidebar-tags li {
  padding: 0;
}

.item-list,
.ver-inline-menu {
  list-style: none;
  margin: 0;
}

.table-advance thead,
.error .select2-container.select2-dropdown-open .select2-choice > span,
.success .select2-container.select2-dropdown-open .select2-choice > span,
.header .hor-menu .search-form form input::-webkit-input-placeholder,
.header .hor-menu .search-form form input:-moz-placeholder,
.header .hor-menu .search-form form input::-moz-placeholder,
.header .hor-menu .search-form form input:-ms-input-placeholder {
  color: #999;
}

.form-row-seperated .control-group.last,
.form-horizontal.form-bordered .control-group.last {
  border-bottom: 0;
}

.form-row-seperated .help-inline,
.form-row-seperated .help-block,
.form-horizontal.form-bordered .help-inline,
.form-horizontal.form-bordered .help-block {
  padding-left: 0;
}

.form-horizontal.form-bordered.form-row-stripped .control-group:nth-child(even),
.form-horizontal.form-bordered.form-label-stripped
  .control-group:nth-child(even) {
  background-color: #fcfcfc;
}

.controls .inline,
.chat-form .btn-cont .btn {
  margin-top: 8px;
}

.tabbable-custom .nav-tabs > li.active,
.header .hor-menu ul.nav li {
  position: relative;
}

.form-wizard .steps .navbar-inner,
.form-wizard .steps .navbar-inner li a {
  background-color: #fff !important;
  background-image: none !important;
  filter: none !important;
  border: 0;
  box-shadow: none !important;
}

.form-wizard .active .step .desc,
.form-wizard .done .step .desc {
  font-weight: 400;
}

.error .select2-container.select2-dropdown-open .select2-choice,
.success .select2-container.select2-dropdown-open .select2-choice {
  border-color: #e5e5e5;
}

.halfings-demo .white-content *,
.halfings-demo .white-content p,
.halfings-demo .white-content a,
.carousel .carousel-caption a {
  color: #fff;
}

.ui-dialog .ui-dialog-titlebar-close:hover,
.header .hor-menu .hor-menu-search-form-toggler:hover,
.header .hor-menu .search-form .btn:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

/*.ui-dialog.ui-dialog-blue .ui-dialog-titlebar-close,
.ui-dialog.ui-dialog-red .ui-dialog-titlebar-close,
.ui-dialog.ui-dialog-yellow .ui-dialog-titlebar-close,
.ui-dialog.ui-dialog-green .ui-dialog-titlebar-close,
.ui-dialog.ui-dialog-purple .ui-dialog-titlebar-close,
.ui-dialog.ui-dialog-grey .ui-dialog-titlebar-close {
  background: url(assets/img/hor-menu-search-close-white.png) no-repeat center;
}*/
/***
Datatables Plugin(in v1.3)
***/
.dataTable {
  clear: both;
  margin-top: 15px;
}

.table tbody > tr:nth-child(even) > td,
.table-striped tbody > tr:nth-child(even) > th {
  background-color: #ededed;
}

.table tbody > tr.even {
  background-color: #ededed;
}

.dataTables_filter label {
  line-height: 31px !important;
  margin-right: 20px;
}

div.dataTables_length label {
  margin-left: 20px;
}

.dataTable .row-details {
  margin-top: 3px;
  display: inline-block;
  cursor: pointer;
  width: 14px;
  height: 14px;
}

.dataTable .row-details.row-details-close {
  background: url('assets/img/datatable-row-openclose.png') no-repeat 0 0;
  width: 14px;
  height: 14px;
}

.dataTable .row-details.row-details-open {
  background: url('assets/img/datatable-row-openclose.png') no-repeat 0 -23px !important;
  width: 14px;
  height: 14px;
}

.dataTable .details {
  background-color: #f9f9f9 !important;
  padding-top: 20px;
}

div.dataTables_info {
  x-padding-top: 25px !important;
  padding-top: 5px !important;
  margin-left: 20px;
}

.pagination ul {
  x-margin-top: 20px;
}

.dataTable .details td,
.dataTable .details th {
  padding-bottom: 0;
  padding-top: 0 !important;
  background-color: none !important;
  border: 1px hidden #fff;
}

.dataTable .details tr:hover td,
.dataTable .details tr:hover th {
  background-color: none !important;
}

.dataTable .details tr:nth-child(odd) td,
.dataTable .details tr:nth-child(odd) th {
  background-color: #f9f9f9 !important;
}

.dataTable .details tr:nth-child(even) td,
.dataTable .details tr:nth-child(even) th {
  background-color: #f9f9f9 !important;
}

div.section-header {
  color: #f2c960;
  background-color: #15354e;
  font-family: open_sansregular;
  text-transform: uppercase;
  padding: 1ex 1em;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
}

div.section-header.first {
  margin-top: 0;
}

div.horizontal-block-list > div {
  padding: 0 1ex 1.5ex 0;
  display: inline-block;
  width: 15em;
}

/***
Responsive tables(new in v1.2.3)
***/

.flip-scroll table {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .flip-scroll table {
    border-left: 1px solid #ddd;
  }
  .flip-scroll .flip-content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
  .flip-scroll * html .flip-content {
    zoom: 1;
  }
  .flip-scroll *:first-child + html .flip-content {
    zoom: 1;
  }

  .flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-scroll th,
  .flip-scroll td {
    margin: 0;
    vertical-align: top;
  }
  .flip-scroll th {
    text-align: left;
  }

  .flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }
  .flip-scroll thead {
    display: block;
    float: left;
  }
  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .flip-scroll thead tr {
    display: block;
  }
  .flip-scroll th {
    display: block;
    text-align: right;
  }
  .flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }

  /* sort out borders */

  .flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }
  .flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .flip-scroll tbody tr {
    border-left: 1px solid #ddd;
  }
  .flip-scroll th:last-child,
  .flip-scroll td:last-child {
    border-bottom: 1px solid #ddd;
  }
}

.no-more-tables table {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-more-tables tr {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .no-more-tables tr:last-child {
    border-bottom: 0;
  }

  .no-more-tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  /*
  Label the data
  */
  .no-more-tables td:before {
    content: attr(data-title);
  }
}

/*
  Added for ui.bootstrap
*/
.nav a,
.pagination a,
.carousel a {
  cursor: pointer;
}

del {
  color: #b94a48;
}

select.agency-configs {
  padding: 0;
  margin: 0;
  width: auto;
}

input[type='text'].agency-configs {
  padding: 0;
  margin: 0;
  width: 300px;
}

textarea.agency-configs {
  padding: 0;
  margin: 0;
  width: 500px;
}

.agency-configs span.counter {
  /*position: absolute;*/
  float: right;
  /*right: -20px;*/
  margin-right: 100px;
  top: 115px;
  font-size: 12px;
  font-family: droid_serifitalic;
  font-style: normal;
  color: #333;
}

blockquote .agency-configs span.counter {
  margin-right: -100px;
}

#admin-instructions .agency-configs span.counter {
  margin-right: 150px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /*Chrome CSS here*/
  .agency-configs span.counter {
    top: 100px; /* was causing the 'characters left' string to appear inside the textarea */
  }
}

.table-last-row {
  border-bottom: 1px solid #ddd;
}

.hover-text {
  text-align: center;
  background-color: #eeeeee;
  color: black;
  padding: 3px 10px;
  position: absolute;
  z-index: 100;
  font-family: open_sansregular;
  cursor: default;
}

.hierarchy-list > ul {
  padding-left: 6px;
}

.hierarchy-list ul li {
  list-style-type: none;
  margin-left: -12px;
  padding-left: 12px;
  text-indent: -12px;
}

.hierarchy-list ul li:before {
  content: '▸ ';
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/* Transplanted Styles That Were Originally Embedded In Each Template */

/*.dataTable .row-details-open {
  background: url('assets/img/datatable-row-openclose.png') no-repeat 0 0;
}

.dataTable .row-details-close {
  background: url('assets/img/datatable-row-openclose.png') no-repeat 0 23;
}*/

.table tbody > tr.parent-row > td {
  background-color: #fff !important;
  color: #343434 !important;
}

.table tbody > tr.subtle-row > td {
  background-color: #f9f9f9 !important;
}

.table thead tr th {
  line-height: 100%;
  padding-bottom: 20px;
}
.table thead tr th strong {
  padding-right: 2px;
}

ul.modal_list {
  margin-bottom: 30px;
}
ul.modal_list li {
  margin-top: 14px;
}

.row-details {
  height: 14px;
  width: 14px;
  cursor: pointer;
  display: inline-block;
}
/*.row-details.row-details-close {
  background: url('../assets/img/datatable-row-openclose.png') no-repeat 0 0;
}

.row-details.row-details-open {
  background: url('../assets/img/datatable-row-openclose.png') no-repeat 0 -23px !important;
}*/

.dropdown-menu {
  width: 245px;
}

integrity-grid-item {
  .dropdown-menu {
    width: 400px;
  }

  .wrappable {
    white-space: normal;
  }
}

/* dataTables Bulk Action Delete, Move, Parent */
.dropdown-menu.narrow {
  width: 40px;
  padding-right: 10px;
}

/* end dataTables Bulk Action Delete, Move, Parent */

@media (min-width: 980px) {
  .page-content {
    min-height: 100vh;
  }
}

input[type='file'] {
  width: 6.3em \0 / IE9;
}

.tab-pane td {
  vertical-align: top;
}

/* find-user.html */
#search-records .span2 {
  width: 16.5%;
}
#search-records-2 .span2 {
  width: 16.5%;
}
#records-search-table-2 td {
  vertical-align: middle;
}

/* Groups.html */
ul#change-parent-group-tree > li:last-child {
  border-bottom: 1px solid #ddd;
}
ul#change-parent-group-tree > li:last-child:first-child {
  border-bottom: none;
}

tbody#permissionsModalTableBody td {
  font-size: 90%;
}
tbody#permissionsModalTableBody td span {
  color: #aaa;
}

#add-person-modal .nav-tabs li.disabled:hover a {
  background-color: #e2e2e2;
  border-color: transparent;
}
#add-person-modal .dataTables_wrapper div.row-fluid:first-child {
  display: none;
}
#add-person-modal .dataTables_wrapper table.dataTable {
  margin-top: 0;
}

ul#transfer-filers-tree > li:last-child {
  border-bottom: 1px solid #ddd;
}
ul#transfer-filers-tree > li:last-child:first-child {
  border-bottom: none;
}

ul#select-parent-group-tree > li:last-child {
  border-bottom: 1px solid #ddd;
}
ul#select-parent-group-tree > li:last-child:first-child {
  border-bottom: none;
}

table#table_filer.suppressCheckboxColumn th:nth-child(1),
table#table_filer.suppressCheckboxColumn td:nth-child(1),
table#table_filer.suppressDefaultFilingTypeColumn th:nth-child(5),
table#table_filer.suppressDefaultFilingTypeColumn td:nth-child(5),
table#table_filer.suppressActionColumn th:nth-child(6),
table#table_filer.suppressActionColumn td:nth-child(6) {
  display: none;
}

div#explore-hierarchy > ul > li:last-child {
  x-border-bottom: 1px solid #ddd !important;
}

/* Table Width Utility Classes */

/*  table class widths */
.w5 {
  width: 5%;
}
.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w50 {
  width: 50%;
}
.w55 {
  width: 55%;
}

/*
			These classes are to allow the tables to have aligned column headers with
			different overall table widths.

			We're dividing a 100% wide table into 18 units of width. 1u = 1 unit wide, 2u = 2 units wide, etc.
		*/
.w18t1u {
  width: 5.555555556%;
} /* 1/18 * 100 */
.w18t2u {
  width: 11.11111111%;
}
.w18t3u {
  width: 16.66666667%;
}
.w18t4u {
  width: 22.22222222%;
}
.w18t5u {
  width: 27.77777778%;
}
.w18t6u {
  width: 33.33333333%;
}
.w18t7u {
  width: 38.88888889%;
}
.w18t8u {
  width: 44.44444444%;
}
.w18t9u {
  width: 50%;
}
.w18t10u {
  width: 55.55555556%;
}
.w18t11u {
  width: 61.11111111%;
}
.w18t12u {
  width: 66.66666667%;
}
.w18t13u {
  width: 72.22222222%;
}
.w18t14u {
  width: 77.77777778%;
}
.w18t15u {
  width: 83.33333333%;
}
.w18t16u {
  width: 88.88888889%;
}
.w18t17u {
  width: 94.44444444%;
}
.w18t18u {
  width: 100%;
}
/* These are for a table that is 16 units wide or 88.88888889% of it's container */
.w16t1u {
  width: 6.25%;
} /* 1/16 * 100 */
.w16t2u {
  width: 12.5%;
}
.w16t3u {
  width: 18.75%;
}
.w16t4u {
  width: 25%;
}
.w16t5u {
  width: 31.25%;
}
.w16t6u {
  width: 37.5%;
}
.w16t7u {
  width: 43.75%;
}
.w16t8u {
  width: 50%;
}
.w16t9u {
  width: 56.25%;
}
.w16t10u {
  width: 62.5%;
}
.w16t11u {
  width: 68.75%;
}
.w16t12u {
  width: 75%;
}
.w16t13u {
  width: 81.25%;
}
.w16t14u {
  width: 87.5%;
}
.w16t15u {
  width: 93.75%;
}
.w16t16u {
  width: 100%;
}
/* These are for a table that is 14 units wide or 77.77777778% of it's container */
.w14t1u {
  width: 7.142857143%;
} /* 1/14 * 100 */
.w14t2u {
  width: 14.28571429%;
}
.w14t3u {
  width: 21.42857143%;
}
.w14t4u {
  width: 28.57142857%;
}
.w14t5u {
  width: 35.71428571%;
}
.w14t6u {
  width: 42.85714286%;
}
.w14t7u {
  width: 50%;
}
.w14t8u {
  width: 57.14285714%;
}
.w14t9u {
  width: 64.28571429%;
}
.w14t10u {
  width: 71.42857143%;
}
.w14t11u {
  width: 78.57142857%;
}
.w14t12u {
  width: 85.71428571%;
}
.w14t13u {
  width: 92.85714286%;
}
.w14t14u {
  width: 100%;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.endnote {
  white-space: nowrap;
}

/* Print */
.print-logo .print {
  display: none;
}
@media print {
  @page {
    size: landscape;
  }

  a[href]:after {
    content: '';
  }

  body {
    background-color: #fff !important;
    width: 100%;
    margin: 0;
    padding: 0;
    float: none;
    /*
                    -moz-transform:rotate(-90deg);
                    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        */
  }

  .spacer {
    margin-bottom: 20px;
  }

  .wizard-content p {
    line-height: 20px;
  }
  hr {
    margin: 0 0 7px 0;
  }
  em {
    font-family: 'open_sansregular';
    font-size: 9px;
    margin-top: 20px;
  }
  .span10 {
    margin-bottom: -40px;
  }
  .na {
    padding-bottom: 40px;
  }
  .page-content {
    margin-left: 0;
  }
  .print-top {
    margin-top: -40px;
  }
  .print-hide {
    display: none;
  }
  .print-logo .print {
    position: absolute;
    top: 20px;
    right: 70px;
    display: block !important;
    width: 180px; /* you can use % */
    height: auto;
  }

  .page-break {
    display: block;
    page-break-before: always;
  }
  tr,
  thead {
    page-break-inside: avoid;
  }
  table,
  tr,
  thead,
  h3,
  h5,
  p.intro,
  .span10 {
    page-break-after: avoid;
  }
  table {
    page-break-before: avoid;
  }

  .navbar-fixed-top,
  .page-sidebar {
    display: none;
    margin: 0px !important;
    padding: 0px !important;
  }
  .page-container,
  .container-fluid {
    margin: 0px !important;
    padding: 0px !important;
  }
  .sidebar-closed > .page-content {
    margin-left: 0px !important;
  }

  table.table tr:last-child,
  table.table thead tr {
    border-bottom: 2px solid #000;
  }
  thead {
    display: table-header-group;
  }
  thead tr th {
    font-weight: bolder !important;
  }

  .coversheet h5 {
    clear: both;
    font-weight: 300;
    line-height: 18px;
  }
  h3 {
    font-family: 'open_sansregular';
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 80px;
  }
  h5 {
    font-family: 'open_sanssemibold';
    margin-top: 20px;
    font-size: 12px;
    font-weight: 300;
    clear: both;
  }

  table {
    border-spacing: 0px;
    padding: 0px;
  }
  table.table-bordered {
    border-left: none;
    border-right: none;
    border-top: 1pt solid #ccc;
  }
  table thead th {
    font-style: bold !important;
    font-size: 9px !important;
    color: #000 !important;
    padding: 2px !important;
    font-size: 10px;
    line-height: 12px !important;
    border-left: none !important;
    border-right: none !important;
  }
  table tbody td {
    padding: 2px !important;
    font-size: 10px;
    line-height: 12px !important;
    border-left: none !important;
    border-right: none !important;
    vertical-align: text-top !important;
  }
  p,
  blockquote,
  ul li,
  ol li {
    font-size: 10px;
    line-height: 12px;
  }
  blockquote {
    border-top: none;
    border-bottom: none;
  }
  .btn {
    display: none;
  }

  .btn-navbar {
    display: none;
  }

  .basic-font {
    font-family: 'Times New Roman';
  }

  p.printfix {
    margin-bottom: 50px;
  }
}

.stats-table td:first-child {
  border-left: 1px solid #ddd;
}

.stats-table td:last-child {
  border-right: 1px solid #ddd;
}

.stats-table td.bottom {
  border-bottom: 1px solid #ddd;
}

.stats-table tr.even-row > td {
  background-color: #ededed !important;
}

.stats-table tr.odd-row > td {
  background-color: #ffffff !important;
}

.datepicker-right {
  position: relative;
  width: 100% !important;
}
.datepicker-right .dropdown-menu {
  left: auto !important;
  right: 0px;
}

.mt_sorting,
.mt_sorting_asc,
.mt_sorting_desc {
  cursor: pointer;
  *cursor: hand;
  padding-right: 20px !important;
}

.mt_sorting {
  background: url('assets/img/sort_both2.png') no-repeat center right;
}
.mt_sorting_asc {
  background: url('assets/img/sort_asc2.png') no-repeat center right;
}
.mt_sorting_desc {
  background: url('assets/img/sort_desc2.png') no-repeat center right;
}

#missing-end-initial-review-date.alert a:link,
#missing-end-initial-review-date.alert a:visited,
#missing-end-initial-review-date.alert a:hover,
#missing-end-initial-review-date.alert a:active {
  color: #0099cc;
  text-decoration: underline;
}

#mising-job-description.alert a:link,
#mising-job-description.alert a:visited,
#mising-job-description.alert a:hover,
#mising-job-description.alert a:active {
  color: #0099cc;
  text-decoration: underline;
}

.warningMessage .alert a:link,
.warningMessage .alert a:visited,
.warningMessage .alert a:hover,
.warningMessage .alert a:active {
  color: #0099cc;
  text-decoration: underline;
}

#compare-button > .btn {
  font-size: 14px;
}

#uploadForm label {
  display: inline-block;
}

.control-group .warning {
  color: #c09853;
}

#bulkActionParentAsset .dropdown-menu {
  width: 400px;
}

#currUploadAttachment {
  display: inline;
  padding-right: 20px;
}

#uploadTransactionFile .controls {
  padding-top: 0;
  vertical-align: middle;
}

#uploadTransactionFile .controls input {
  height: auto;
  line-height: 0;
  margin: 0;
  font-family: open_sansregular;
}

#import-spreadsheet-heading,
#downloadTemplate {
  display: inline-block;
  margin: 1em 2em 1em 0;
  width: auto;
}

#import-spreadsheet-heading label,
#import-spreadsheet-heading a {
  display: inline;
}

.warning-list {
  padding-top: 3px;
}

.warningMessage {
  padding-top: 1em;
}

/* EFEDS-6072 make dialog titles uppercase through css so they can be title case for screen readers */
.modal h3 {
  text-transform: uppercase;
}
.modal h4 {
  text-transform: uppercase;
}

.report-status .modal-dialog {
  max-width: 700px;
  height: 500px;
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
}

table.table thead .sorting {
  background: url('assets/img/data-tables/sort_both.png') no-repeat center right
    #15354e;
}
table.table thead .sorting.asc {
  background: url('assets/img/data-tables/sort_asc.png') no-repeat center right
    #15354e;
}
table.table thead .sorting.desc {
  background: url('assets/img/data-tables/sort_desc.png') no-repeat center right
    #15354e;
}

.menu-view-toggler-container {
  padding-bottom: -5px !important;
  text-align: center;
}

.page-sidebar-closed .btn-group.menu-view-toggler .btn-primary {
  display: none;
}
.page-sidebar-closed .btn-group.menu-view-toggler .btn-primary.active {
  display: inline-block;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  -webkit-border-top-left-radius: 5px !important;
  -webkit-border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  -webkit-border-top-right-radius: 5px !important;
  -webkit-border-bottom-right-radius: 5px !important;
}
.page-sidebar-closed .btn-group.menu-view-toggler .btn-primary:hover {
  cursor: default !important;
}
.page-sidebar-closed .btn-group.menu-view-toggler .btn-primary.active span {
  display: none;
}
.page-sidebar-closed .btn-group.menu-view-toggler .btn-primary a:hover {
  text-decoration: none !important;
  cursor: default !important;
}
@media (max-width: 979px) {
  .menu-view-toggler-container {
    padding-bottom: 15px !important;
  }
  .nav-collapse .page-sidebar-menu {
    margin-top: -15px !important;
  }
  .nav-collapse.in .page-sidebar-menu {
    margin-top: 0 !important;
  }
  .btn-group.menu-view-toggler {
    top: -5px;
  }
}
.btn-group.menu-view-toggler .btn-primary {
  background-color: white;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  border-left: 1px solid #343434 !important;
  border-right: 1px solid #343434 !important;
  border-bottom: none !important;
  margin: 0px !important;
  height: auto;
}
.btn-group.menu-view-toggler .btn-primary:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  -webkit-border-top-left-radius: 5px !important;
  -webkit-border-bottom-left-radius: 5px !important;
  border-right: none !important;
}
.btn-group.menu-view-toggler.exactly-two-buttons .btn-primary:first-child {
  border-right: 1px solid #343434 !important;
}
.btn-group.menu-view-toggler .btn-primary:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  -webkit-border-top-right-radius: 5px !important;
  -webkit-border-bottom-right-radius: 5px !important;
  border-left: none !important;
}
.btn-group.menu-view-toggler a:hover {
  text-decoration: none !important;
}
.btn-group.menu-view-toggler > :hover {
  background-color: #ddd;
  color: #333;
}
.btn-group.menu-view-toggler .active {
  background-color: #f2c960 !important;
  color: #333;
}

.modal-footer {
  background-color: #f5f5f5;
}

.error-msg {
  color: #b94a48 !important;
  padding-bottom: 8px;
  padding-left: 5px;
}

.form-error {
  border-color: #b94a48 !important;
}

.pull-right {
  float: right;
}

/* Part of admin/find-user module but needs to be here
   because the <hr> is an innerHTML markup and not accessible
   to the Angular style component scope manager */
.find-user-html-column hr {
  margin: 5px;
}

.lr-borders {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.inactive {
  color: gray;
  border: 1px solid gray;
  font-size: 10px !important;
  padding: 0.1ex 1ex;
  background-color: lightgray;
  text-transform: uppercase;
  position: relative;
  top: -1px;
}
div.form-check.disabled,
div.form-group.disabled,
div.form-group.disabled > label,
div.form-check.disabled > label,
div.form-group textarea[disabled],
div.form-group select[disabled] {
  cursor: not-allowed;
}

.hamburger-tooltip .tooltip-inner {
  background-color: #15354e;
}

.hamburger-tooltip .arrow::before {
  /* using border-right here because we force the tooltip to the right */
  border-right-color: #15354e;
}

/* Enable scrollbars for modals with contents wrapped in <span>s... */
.modal-body {
  max-height: 500px;
}
