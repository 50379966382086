/* Shared css for form layout. */

.dashboard-body {
  margin-left: 16px;
}

/*.section-block {F
  padding-top: 2em;
}*/

select {
  display: inline-block;
  height: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fff;
  padding: 10px;
  border: 1px solid #cccccc;
}

.onboarding {
  background: #eee url(assets/img/noise-texture.png);
}

.control-group.error .control-label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #b94a48;
}

.btn.yellow,
.btn-thin.yellow {
  color: #000;
  text-shadow: none;
  background-color: #f2c960;
  border-bottom: 1px solid #dfb553;
}

.alert-danger,
.alert-error {
  color: #333;
  background-color: #f7e8e8;
  border-color: #e2acac;
  line-height: 24px;
}

.alert {
  padding: 12px 35px 12px 14px;
}

.wizard-content {
  padding: 25px 10px 0px 10px;
}

input[disabled],
input[readonly] {
  cursor: not-allowed;
}

input[type='date'] {
  border: 1px solid rgb(204, 204, 204);
  height: 40px;
}

label {
  font-family: 'open_sanssemibold';
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 0px;
  color: #15354e;
  display: block;
}

label.radio {
  display: block;
  float: left;
  width: 100%;
}

input {
  border-width: 1px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  box-shadow: none;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  padding: 4px 10px 4px 10px;
}

input[type='radio']:not(old) {
  width: 22px;
  margin: 0;
  padding: 0;
  opacity: 0;
}

input[type='radio']:not(old) + label {
  display: inline;
  margin-left: -22px;
  padding-left: 22px;
  background: url('assets/img/forms/radio-unchecked.png') no-repeat 0 0;
}

input[type='radio']:not(old) + label:not(.filter-label) {
  font-family: 'open_sansregular';
  font-style: normal;
  text-transform: none;
  font-weight: normal;
}

input[type='radio']:not(old) + label.filter-label {
  font-weight: 500;
  text-transform: uppercase;
}

input[type='radio']:not(old):active + label {
  background: url('assets/img/forms/radio-unchecked-active.png') no-repeat 0 0;
}

input[type='radio']:not(old):disabled + label {
  background: url('assets/img/forms/radio-unchecked-disabled.png') no-repeat 0 0;
}

input[type='radio']:not(old):disabled:active + label {
  background: url('assets/img/forms/radio-unchecked-disabled-active.png')
    no-repeat 0 0;
}

input[type='radio']:not(old):checked + label {
  background: url('assets/img/forms/radio-checked-active.png') no-repeat 0 0;
}

input[type='radio']:not(old):checked:active + label {
  background: url('assets/img/forms/radio-checked-active.png') no-repeat 0 0;
}

input[type='radio']:not(old):checked:disabled + label {
  background: url('assets/img/forms/radio-checked-disabled.png') no-repeat 0 0;
}

input[type='radio']:not(old):checked:disabled:active + label {
  background: url('assets/img/forms/radio-checked-disabled-active.png')
    no-repeat 0 0;
}

input[type='checkbox']:not(old) {
  width: 24px;
  margin: 0;
  padding: 0;
  opacity: 0;
}

input[type='checkbox']:not(old) + label {
  display: inline;
  margin-left: -24px;
  padding-left: 24px;
  background: url('assets/img/forms/checkbox-unchecked.png') no-repeat 0 0;
}

input[type='checkbox']:not(old) + label:not(.filter-label) {
  font-family: 'open_sansregular';
  font-style: normal;
  text-transform: none;
  font-weight: normal;
}

input[type='checkbox']:not(old) + label.filter-label {
  font-weight: 500;
  text-transform: uppercase;
}

input[type='checkbox']:not(old):active + label {
  background: url('assets/img/forms/checkbox-unchecked-active.png') no-repeat 0
    0;
}

input[type='checkbox']:not(old):disabled + label {
  background: url('assets/img/forms/checkbox-unchecked-active.png') no-repeat 0
    0;
  cursor: not-allowed;
}

input[type='checkbox']:not(old):disabled:active + label {
  background: url('assets/img/forms/checkbox-unchecked-disabled-active.png')
    no-repeat 0 0;
}

input[type='checkbox']:not(old):checked + label {
  background: url('assets/img/forms/checkbox-checked.png') no-repeat 0 0;
}

input[type='checkbox']:not(old):checked:active + label {
  background: url('assets/img/forms/checkbox-checked-active.png') no-repeat 0 0;
}

input[type='checkbox']:not(old):checked:disabled + label {
  background: url('assets/img/forms/checkbox-checked-disabled.png') no-repeat 0
    0;
}

input[type='checkbox']:not(old):checked:disabled:active + label {
  background: url('assets/img/forms/checkbox-checked-disabled-active.png')
    no-repeat 0 0;
}

/* For older browsers */
input[type='radio'] {
  height: 18px;
}

/* For older browsers, remove rectangular border around the radio button */
input[type='radio']:focus {
  outline: 0px solid transparent;
}

input[type='file'] {
  border: none;
}

input.no-value {
  font-family: droid_serifregular;
  background: #fef9ee;
}

.dashboard-body i.fa-question-circle {
  padding-left: 3px;
}

a i.fa-question-circle {
  color: #333;
}

i.fa-question-circle:hover, i.fa-question-circle:active {
  color: #767676;
  cursor: pointer;
}

button.pull-right {
  float: right;
}

.alert-error {
  color: #333;
  background-color: #f7e8e8;
  border: 1px solid #e2acac;
  line-height: 24px;
  padding: 12px 35px 12px 14px;
}

textarea + .counter {
  text-align: right;
  font-size: 12px;
  font-family: droid_serifitalic;
  font-style: normal;
  color: #333;
}

.form-check-input:disabled {
  opacity: 0;
}
