body {
  min-height: 100%;
  background-color: #15354e !important;
}
h1,
h2 {
  font-size: 26px;
  font-family: droid_serifregular;
  margin: 20px 0 15px 0;
  line-height: 36px;
}
h2 {
  font-size: 24px;
}

h3 {
  font-family: droid_serifregular;
}
h4 {
  font-family: droid_serifregular;
  margin-top: 32px;
  line-height: 26px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
hr.blue {
  border: 0;
  border-top: 1px solid #d8dde0;
  border-bottom: 1px solid #fefefe;
  margin: 0 0 20px 0;
}
hr.showhide {
  margin: 5px 0 15px 0;
}
.noisy-bg {
  background: #eee url(assets/img/noise-texture.png);
  padding-bottom: 1px;
}
.ie8 .noisy-bg {
  padding: 0 30px 35px 30px;
}
.wizard-content {
  padding: 25px 10px 0px 10px;
}
.wizard-content p {
  line-height: 24px;
}

.container-fluid {
  padding-left: 19px;
  padding-right: 29px;
}

.table > :not(caption) > * > * {
  padding: 0.75rem;
}

.form-wrap [class^='col-'] {
  background-color: #fff;
}

.intro-text-large {
  font-family: droid_serifitalic;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
}
.intro-text-small {
  font-family: droid_serifitalic !important;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
  padding-bottom: 10px;
}
:focus,
a:focus,
.btn:focus,
select:focus,
img:focus,
input:focus,
input[type='text']:focus,
input[type='file']:focus,
input[type='checkbox']:focus,
div.focus,
div.select2-container-active a.select2-choice,
div.checker.focus span {
  outline: 2px solid #0af;
  outline-offset: 0px; /*-2px;*/
  /*transition: none;
	-webkit-border-radius: 5px !important;
	-moz-border-radius: 5px !important;
	-ms-border-radius: 5px !important;
	-o-border-radius: 5px !important*/
}
label.hasModal {
  margin-right: 5px;
}
label.hasModal {
  display: inline;
}
label.instructions em {
  font-family: 'droid_serifitalic';
  font-style: italic;
  font-weight: normal;
  text-transform: none;
  color: #1a1a1a;
}
div.dataTables_filter div.searchDiv label {
  float: left;
  width: 20%;
  text-align: right;
  margin-right: 0;
}
div.dataTables_filter div.searchDiv input {
  float: right;
  margin-right: 20px;
}
span.perpageSpan {
  margin-left: 20px;
}

div.dropdown-checkboxes label {
  display: inline;
}

p.instructions-ital {
  font-family: 'droid_serifitalic' !important;
  font-size: 15px;
  line-height: 28px;
  color: #333;
}

p.reminder-ital {
  font-family: droid_serifitalic;
  font-size: 15px;
  line-height: 29px;
  padding-bottom: 10px;
}
p.reminder {
  font-family: droid_serifregular;
  font-size: 13px;
  line-height: 26px;
  padding-bottom: 10px;
}
p.intro {
  padding-top: 4px;
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
}
p.intro-status {
  font-style: italic;
  font-size: 14px;
  padding-left: 20px;
}
p.instructions {
  font-size: 14px !important;
  line-height: 22px;
}
a.warning {
  color: #f2c960;
}
em {
  font-family: droid_serifitalic;
  font-style: normal;
  text-transform: none;
}

img {
  max-width: none;
}

.blue-container {
  padding: 10px 20px 10px 20px;
}

.blue-container p {
  font-family: droid_serifregular;
  padding-bottom: 10px;
}
.blue-container .label-italic {
  line-height: 26px !important;
}
div.media {
  margin-bottom: -10px;
  margin-top: 0;
}
.media:first-child {
  margin-top: 3px;
}
.blue {
  color: #4d768c !important;
}
.trashcan {
  color: #4d768c !important;
  background-image: url(assets/img/trashcan.jpg);
  background-repeat: no-repeat;
  padding-left: 15px;
}
.blue-align {
  padding-top: 10px;
}
.progress {
  height: 15px;
  margin-top: 5px;
  padding-right: 0;
  background: #e2e2e2;
}
.progress .bar {
  background-image: linear-gradient(to bottom, #0298cb, #0298cb);
}
.progress-thin {
  width: 70px;
  height: 10px;
  margin: 20px 0 24px 0;
  overflow: hidden;
  background-color: #e2e2e2;
  background-repeat: repeat-x;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
}
.progress-thin .bar {
  float: left;
  width: 0px;
  height: 100%;
  font-size: 12px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
  background-color: rgb(14, 144, 210);
  background-image: linear-gradient(to bottom, #0298cb, #0298cb);
  background-repeat: repeat-x;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.15) inset;
  -moz-box-sizing: border-box;
  transition: width 0.6s ease 0s;
}
.progress-thin-warning {
  width: 70px;
  height: 10px;
  margin: 8px 0 24px 0;
  overflow: hidden;
  background-color: #e2e2e2;
  background-repeat: repeat-x;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
}
.progress-thin-warning .bar {
  float: left;
  width: 0px;
  height: 100%;
  font-size: 12px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
  background-color: rgb(14, 144, 210);
  background-image: linear-gradient(to bottom, #f2c960, #f2c960);
  background-repeat: repeat-x;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.15) inset;
  -moz-box-sizing: border-box;
  transition: width 0.6s ease 0s;
}

.page-sidebar-closed .page-content {
  margin-left: 40px !important;
}
/*.page-content {
  padding-left: 35px;
  padding-right: 35px;
}*/
.page-content {
  min-height: 100vh;
}
.page-sidebar-closed .page-sidebar {
  width: 40px;
}

.page-sidebar {
  background-color: #15354e;
}

.page-sidebar-closed ul.page-sidebar-menu {
  width: 40px !important;
}

.page-sidebar-closed ul.page-sidebar-menu > li > a {
  padding-left: 12px;
}

table {
  width: 100%;
  background-color: #fff;
}
.table-container {
  border: 1px solid #ddd;
  padding: 20px 0 20px 0;
  margin: 0 0 40px 0;
  background-color: #f5f5f5;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #ededed !important;
}

.table-expand {
  margin: 0;
  padding: 0;
}
.hidden {
  display: none !important;
}
tr.hidden-row {
  background-color: #f9f9f9;
  color: #9e9e9e;
  display: none;
}
tr.greyed-row {
  color: #9e9e9e;
}
tr.unsubtle-row td {
  color: #333;
}
td i {
  color: #767676;
}

.row {
  margin-left: 0;
}

.row-fluid .span-100 {
  padding-right: 40px !important;
}

.row-fluid .span-69 {
  width: 69.63%;
}

.row-fluid .span-27 {
  min-height: 100% !important;
  width: 27.8%;
  padding: 0 30px 0 30px;
  background: #eee url(assets/img/noise-texture.png);
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.span-27 .label-italic {
  padding-top: 5px;
  margin-bottom: -3px;
}
.row-fluid .span-85 {
  width: 80% !important;
  padding-left: 0;
}

.row-fluid .span-10 {
  width: 10% !important;
  padding-top: 37px;
  margin-right: 10px;
}

.row-fluid .span-3 {
  width: 3.19148936170213%;
}

.row-fluid .span-9 {
  width: 9%;
}

div.dataTables_length label {
  line-height: 31px;
}

h3.right-panel {
  line-height: 30px;
  font-size: 22px;
  color: #2f2f2f;
  margin-top: 11px;
  padding-bottom: 15px;
}

/*.container-fluid {
  padding: 0 35px 35px 35px;
}*/
.grid {
  padding-right: 0;
  padding-bottom: 0;
}
.grid-padding {
  padding-left: 30px;
  padding-right: 0;
}
.page-header-fixed .page-container {
  margin-top: 0;
}
.doc-top {
  padding-top: 7px;
}

.radio input[type='radio'],
.checkbox input[type='checkbox'] {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}
.radio,
.checkbox,
.checkbox-label {
  font-family: 'open_sansregular' !important;
  font-style: normal !important;
  text-transform: none !important;
  font-weight: normal !important;
}
.script {
  margin-top: -5px !important;
}
.title {
  font-family: 'open_sanssemibold';
  letter-spacing: 0.08em;
  padding-left: 2px;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

.tree {
  padding-left: 20px;
}

.trash {
  padding-left: 4px;
}
.ui-dialog {
  padding: 0;
}
.ui-dialog-title {
  padding: 9px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 0px !important;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 0px !important;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 0px !important;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 0px !important;
}

.ui-dialog .ui-dialog-buttonpane {
  padding: 10px 10px 10px 0 !important;
}

.ui-dialog .ui-dialog-content {
  line-height: 22px;
  overflow: hidden !important;
  padding: 20px !important;
}

.ui-dialog .ui-dialog-buttonpane button {
  padding: 10px 20px;
}

strong {
  font-family: 'open_sanssemibold';
  font-weight: 500;
}

.pagination ul {
  box-shadow: none;
}

.show-content {
  display: block;
}

.ui-widget-content {
  background-color: #fff !important;
}

.cke_top {
  border-top: 1px solid #b6b6b6 !important;
}

.cke_bottom {
  border-bottom: 1px solid #b6b6b6 !important;
}

.header .navbar-inner {
  filter: none !important;
  background-image: none !important;
  background-color: #15354e !important;
}

.header .btn-navbar {
  background-color: #15354e !important;
}

.header .nav .dropdown-toggle:hover,
.header .nav .dropdown.open .dropdown-toggle {
  background-color: #383838 !important;
}

a.btn-thin.dropdown-toggle {
  color: #333;
}

a.btn-thin.dropdown-toggle:hover,
a.btn-thin.dropdown-toggle:focus {
  text-decoration: none !important;
  color: #0d638f;
}

.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover,
.btn-group > .dropdown > .btn,
.btn.big-label,
label,
.faux-label,
input,
button,
select,
textarea {
  font-size: 14px;
}

.dropdown-menu {
  left: auto;
  right: 0;
}

ngb-datepicker.dropdown-menu {
  right: auto;
  width: auto;
}

.dataTables_length select {
  height: 40px;
}

a.btn {
  background-color: #e2e2e2;
  background-image: none;
  filter: none;
  border: 0;
  border-bottom: 1px solid #cdcdcd;
  text-shadow: none;
  font-size: 12px;
  font-weight: 400;
  color: #333 !important;
  cursor: pointer;
  /*	outline:none; dgf */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
  font-family: open_sanssemibold;
  padding: 10px 15px 10px 15px;
  height: 19px;
  text-decoration: none !important;
}
.ie8 .btn-group > .btn {
  margin-left: 10px !important;
}

.btn {
  background-color: #e2e2e2;
  background-image: none;
  filter: none;
  border: 0;
  border-bottom: 1px solid #cdcdcd;
  text-shadow: none;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  /*	outline:none; dgf */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
  font-family: open_sanssemibold;
  padding: 5px 15px 5px 15px;
  height: 40px;
  --bs-btn-active-bg: #e2e2e2;
  --bs-btn-active-border-color: #e2e2e2;
}
.btn-thin {
  white-space: nowrap;
  background-color: #e2e2e2;
  background-image: none;
  filter: none;
  border: 0;
  border-bottom: 1px solid #cdcdcd;
  text-shadow: none;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  /*	outline:none; dgf */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
  font-family: open_sanssemibold;
  padding: 0 10px 0 10px;
  height: 30px !important;
}
.ie8 .btn.grey {
  margin-left: 10px;
}
.ie8 .btn-group > .btn:first-child {
  margin-right: 0px;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled],
.btn-thin.disabled,
.btn-thin[disabled] {
  background-color: #e2e2e2;
}
.btn.disabled,
.btn[disabled],
.btn-thin.disabled,
.btn-thin[disabled] {
  background-color: #e2e2e2;
  cursor: not-allowed;
  color: #999;
}
.btn.yellow,
.btn-thin.yellow {
  color: #000;
  text-shadow: none;
  background-color: #f2c960;
  border-bottom: 1px solid #dfb553;
  --bs-btn-active-bg: #f2c960;
  --bs-btn-active-border-color: #f2c960;
}
.btn.yellow[disabled],
.btn-thin.yellow[disabled],
.btn.yellow.disabled,
.btn-thin.yellow.disabled {
  color: #333;
}
label.disabled {
  cursor: not-allowed;
}
.green {
  color: #f7e8e8;
  text-shadow: none;
  background-color: #679c04;
  border-bottom: 1px solid #658530;
}
.button-row {
  float: left;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
.btn-top {
  margin-top: 14px;
  float: right;
}
.btn-top > .btn {
  margin-top: 6px;
}
.btn-top > .btn:first-child {
  margin-right: 6px;
}
.btn-top > .btn:nth-child(2) {
  margin-right: 6px;
}
.btn-top > .btn:last-child {
  margin-right: 0px;
}
.ie8 .btn-top > .btn:first-child {
  margin-right: 0;
}
.last-one {
  padding-bottom: 20px;
}
.btn-bottom {
  margin-bottom: 20px;
  text-align: center;
}
.btn-footer {
  margin: 12px 0 40px 0;
}
.btn-footer > .btn {
  margin-top: 6px;
}
.btn-footer > .btn:first-child {
  margin-right: 6px;
}
.btn-footer > .btn:nth-child(2) {
  margin-right: 6px;
}
.btn-footer > .btn:last-child {
  margin-right: 0px;
}
.ie8 .btn-footer > .btn:first-child {
  margin-right: 0;
}
.btn-group {
  white-space: nowrap;
}
.btn-group-datatable {
  padding: 20px;
}
.btn-group > .btn:first-child {
  margin-right: 6px;
}
.btn-group > .btn:nth-child(2) {
  margin-right: 6px;
}
.btn-group > .btn:last-child {
  margin-right: 0px;
}
.btn-group-b {
  padding-top: 20px;
  white-space: nowrap;
}

.btn-group-b > .btn:first-child {
  margin-right: 16px;
}
.btn-group-b > .btn:nth-child(2) {
  margin-right: 6px;
}
.btn-group-b > .btn:last-child {
  margin-right: 0px;
}
.next {
  margin-right: 20px !important;
  border-bottom: 1px solid #cdcdcd;
}
.btn-footer-script {
  margin: 8px 0 10px 0;
}
.btn-footer-script > .btn {
  margin-top: 0;
}
.btn-footer-script > .btn:first-child {
  margin-right: 10px;
}
.btn-footer-script > .btn:nth-child(2) {
  margin-right: 10px;
}
.btn-footer-script > .btn:last-child {
  margin-right: 0px;
}
.ie8 .btn-footer-script > .btn:first-child {
  margin-right: 0;
}
.previous {
  margin-left: 20px !important;
  border-bottom: 1px solid #cdcdcd;
}
.profile-top-btn {
  margin-top: 35px;
}
#endnote1 {
  color: #0d638f;
}

#delete_opener {
  padding-left: 4px;
}

.ui-dialog.ui-dialog-blue .ui-dialog-titlebar {
  color: #f2c960 !important;
  background-color: #15354e !important;
}

.onboarding {
  background: #eee url(assets/img/noise-texture.png);
}

.logo-header {
  width: 400px;
  margin: 40px auto;
}

.welcome-subheader {
  font-size: 14px;
  font-family: open_sansbold;
  letter-spacing: 1px;
  width: 100%;
  background-color: #15354e;
  color: #ecc256;
  text-align: center;
  line-height: 50px;
}

.onboard-body {
  width: 90%;
  color: #333;
  padding-bottom: 10px;
  margin-right: auto;
  max-width: 800px;
  margin-top: 20px;
}
.onboard-body p {
  font-family: droid_serifregular;
  font-size: 14px;
  line-height: 30px;
  padding-bottom: 15px;
}

.onboard-body-wide .oge-wizard-error-list p {
  text-align: left;
  font-family: open_sansregular;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0;
}

.onboard-body p.intro {
  font-family: droid_serifitalic;
  padding-top: 10px;
  font-size: 18px;
  line-height: 34px;
}

.onboard-body p.onlabel {
  font-family: droid_serifitalic;
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 20px;
}

.onboard-body p.outro {
  font-family: droid_serifitalic;
  font-size: 14px;
  line-height: 26px;
  margin-top: 10px;
}
.body-text-left {
  text-align: left;
}
.onboard-body-wide {
  width: 90%;
  color: #333;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  margin-top: 20px;
}
.onboard-body-wide p {
  font-family: droid_serifregular;
  font-size: 14px;
  line-height: 30px;
  padding-bottom: 15px;
  text-align: center;
}

.onboard-body-wide p.intro {
  font-family: droid_serifitalic;
  padding-top: 10px;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
}

.onboard-body-wide p.onlabel {
  font-family: droid_serifitalic;
  font-size: 18px;
  line-height: 32px;
  padding-bottom: 20px;
}

.onboard-body-wide p.outro {
  font-family: droid_serifitalic;
  font-size: 14px;
  line-height: 26px;
}

.ie8 input.onboarding {
  padding-top: 10px;
  height: 24px;
}
.ie8 input.no-value {
  padding-top: 10px;
  height: 24px;
}

.ie8 .push-right {
  margin-right: 20px !important;
}
.controls {
  margin-right: 20px;
  padding-top: 6px;
}
.controls-nomargin {
  padding-top: 6px;
  margin-right: 0px;
}
.controls-no-label {
  padding-top: 27px;
  margin-right: 0px;
}

.controls .controls .control-group.success .control-label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: rgb(89, 89, 89);
}
.control-group.success input,
.control-group.success select,
.control-group.success textarea,
.control-group.success .checkbox,
.control-group.success .radio {
  border-color: rgb(204, 204, 204);
  color: rgb(51, 51, 51);
  background: #fff;
}
.control-group i {
  color: #15354e;
  font-size: 15px;
  /*margin-left: 10px;*/
}

.control-group i:hover {
  color: #767676;
  cursor: pointer;
}
label,
.faux-label {
  font-family: 'open_sanssemibold';
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 0px;
  color: #15354e;
  display: block;
}
label > .label-note {
  font-family: 'droid_serifitalic';
  text-transform: none;
  font-weight: normal;
  font-size: 90%;
}
.label-submission {
  padding-top: 40px;
  font-family: 'open_sanssemibold';
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  padding-bottom: 0px;
  color: #333;
}
.label-padding {
  padding: 0 10px 0 10px;
}
.label-italic {
  font-family: 'droid_serifitalic' !important;
  font-style: normal !important;
  text-transform: none !important;
  font-weight: normal !important;
  padding-bottom: 5px;
  line-height: 26px;
}
.label-progress {
  margin: 0px;
  font-size: 14px;
}
.wizard-box-body .label-italic {
  padding-top: 10px;
  line-height: 13px;
}

.label-normal {
  font-family: 'open_sansregular' !important;
  font-style: normal !important;
  text-transform: none !important;
  font-weight: normal !important;
}
.badge {
  padding: 2px 6px 2px 6px;
  border-radius: 4px 4px 4px 4px;
  font-weight: normal;
  text-shadow: none;
}
.badge-round {
  border-radius: 8px 8px 8px 8px;
}
.badge-warning {
  font-weight: 300;
  color: #333;
  background-color: #f2c960;
  background-image: none !important;
  text-shadow: none !important;
}
.badge-round-counter {
  text-align: center;
  font-size: 10px;
  font-weight: 300;
  background-color: #334859;
  margin-right: 8px;
  text-shadow: none !important;
  min-width: 25px;
}
.control-group > p {
  text-align: left;
  padding-bottom: 0;
  font-family: 'open_sansregular';
  font-size: 14px;
}

input[type='text'],
input[type='email'],
input[type='number'] {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  box-shadow: none;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
  font-family: droid_serifregular;
  display: inline-block;
  height: 2.85em;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fff;
  padding: 4px 10px;
}

select {
  display: inline-block;
  height: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  width: 100%;
  background: #fff;
  padding: 10px;
}
select.no-value {
  display: inline-block;
  height: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fef9ee;
  padding: 10px;
}

input.grid {
  font-family: droid_serifregular;
  display: inline-block;
  height: 40px !important;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 95%;
  background: #fff;
  padding: 4px 10px;
}
/*

input {
  font-family: droid_serifregular;
  display: inline-block;
  height: 30px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fff;
  padding: 4px 10px;
}
.ie8 input {
  height: 24px;
  padding-top: 10px;
}

input.onboarding-alert {
  font-family: droid_serifregular;
  display: inline-block;
  height: 30px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 95%;
  background: #fff;
  padding: 4px 10px;
  border: 1px solid #b22020;
}
input.no-value {
  font-family: droid_serifregular;
  display: inline-block;
  height: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fef9ee;
  padding: 4px 10px;
}
textarea.onboarding {
  font-family: droid_serifregular;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 95%;
  background: #fff;
  padding: 4px 10px;
}
textarea.no-value-white {
  font-family: droid_serifregular;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fff;
  padding: 4px 10px;
  margin-top: -5px;
}*/
textarea.no-value {
  font-family: droid_serifregular;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  background: #fef9ee;
  padding: 4px 10px;
}

.ie8 input.designee {
  padding-top: 10px;
  height: 24px;
}

input.designee {
  font-family: droid_serifregular;
  display: inline-block;
  height: 30px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  vertical-align: middle;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  max-width: 180px;
  width: 75%;
  background: #fff;
  padding: 4px 10px;
}

.onboard-form {
  padding-bottom: 15px;
}

p.last-label {
  margin-top: 10px;
}

.spacer {
  padding-bottom: 20px;
}

.spacer-15 {
  padding-bottom: 15px;
}

.logo-block img {
  float: left;
  padding: 20px 20px 20px 0;
}

.logo-block-txt {
  padding-top: 4px;
}

.logo-block h4 {
  color: #15354e;
  line-height: 14px;
}

.logo-block h4.dashboard {
  margin-top: 40px;
  line-height: 20px;
}

.designee-block {
  margin-top: 23px;
  padding: 10px;
}
.designee-block form {
  margin-top: 20px;
}

.designee-block p {
  line-height: 26px;
}
.dashboard-head {
  background-color: #15354e;
  color: #f6c759;
  margin: 15px 0 0 0;
  padding: 15px 20px 6px 20px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.dashboard-head p,
.dashboard-head h3,
.dashboard-head h2 {
  text-transform: uppercase;
  font-family: 'open_sanssemibold';
}
.dashboard-head h3,
.dashboard-head h2,
.dashboard-head h1,
.dashboard-head h4 {
  font-family: open_sansregular;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 10px;
}
.dashboard-subhead {
  vertical-align: middle;
  background-color: #fff;
  font-family: droid_serifregular;
  font-size: 18px;
  display: inline;
  color: #15354e;
  margin: 0;
}
.dashboard-subhead img {
  padding-bottom: 20px;
  padding-right: 20px;
}

.dashboard-body {
  background-color: #fff;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #ddd;
  margin: 0 0 30px 0;
}
.dashboard-body-alt {
  background-color: #fff;
  margin: 0 0 30px 0;
}
.designee-body {
  float: left;
  background-color: #fff;
  padding: 0 20px 3px 20px;
  border-bottom: 1px solid #ddd;
}
.profile-body {
  background-color: #fff;
  padding: 0 20px 3px 20px;
  border-bottom: 1px solid #ddd;
  margin: 0 0 35px 0;
}
.dashboard-footer {
  margin-top: -10px;
  padding-bottom: 40px;
}

.dashboard-row {
  padding: 19px 0 12px 0;
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
}

.dashboard-row img {
  float: right;
  padding-top: 4px;
}
.designee-row {
  padding: 13px 0 3px 0;
  float: left;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.designee-row-last {
  padding: 20px 0 0 0;
  float: left;
  width: 100%;
}
.label-column-alt {
  width: 93px;
  float: left;
  padding-left: 20px;
}
.label-column {
  width: 93px;
  float: left;
}
.label-column p {
  font-family: 'droid_serifitalic';
}
.label-caps {
  text-transform: uppercase;
  font-family: open_sanssemibold !important;
  font-size: 14px;
  color: #15354e;
  padding-bottom: 0;
}
.label-plain {
  font-family: 'open_sansregular' !important;
  text-transform: none !important;
}
.text-display {
  font-family: open_sanssemibold !important;
  font-size: 14px;
  color: #15354e;
  padding-bottom: 0;
}
.label-warning,
.badge-warning {
  background-color: #f2c960;
  color: #333;
  font-weight: normal;
}
.label-changed,
.badge-changed {
  color: #333;
  font-weight: normal;
  background-color: #fef9ee;
  border: 1px solid #edd7a1;
  line-height: 24px;
  padding: 0 10px 0 10px;
}
.data-column {
  float: left;
}
.wizard-box-head {
  float: left;
  width: 100%;
  border-top: 1px solid #d8dde0;
  border-left: 1px solid #d8dde0;
  border-right: 1px solid #d8dde0;
  background-color: #d4e8f9;
  color: #15354e;
  margin-top: 10px;
  padding: 15px 0 6px 0;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.wizard-box-head p {
  padding-left: 20px;
  text-transform: uppercase;
  font-family: 'open_sanssemibold';
}

.wizard-box-body {
  float: left;
  width: 100%;
  background-color: #f3faff;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #d8dde0;
  border-left: 1px solid #d8dde0;
  border-right: 1px solid #d8dde0;
}

.wizard-box-head-alert {
  float: left;
  width: 100%;
  border-top: 1px solid #d8dde0;
  border-left: 1px solid #d8dde0;
  border-right: 1px solid #d8dde0;
  background-color: #b22020;
  color: #fff;
  margin-top: 10px;
  padding: 15px 0 6px 0;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.wizard-box-head-alert > .label-caps-white {
  padding-left: 20px;
}

.wizard-box {
  padding-left: 20px;
  margin-bottom: 0;
}

/* bootstrap5 replace form-inline with inline-form */
.form-inline .form-control,
.form-inline .radio,
.form-inline .checkbox,
.inline-form .form-control,
.inline-form .radio,
.inline-form .checkbox {
  display: inline-block;
}
.form-search label,
.form-search .btn-group,
.inline-form label,
.inline-form .btn-group {
  display: inherit;
}
.form-inline label,
.inline-form label,
label.inline-label {
  font-family: 'open_sansregular' !important;
  text-transform: none !important;
}

/* bootstrap5 replace form-inline with inline-form */
.inline-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.alert {
  padding: 12px 35px 12px 14px;
}
.alert .close {
  position: relative;
  top: 3px;
  right: -20px;
  line-height: 20px;
  opacity: 1 !important;
}

.alert-danger,
.alert-error {
  color: #333;
  background-color: #f7e8e8;
  border-color: #e2acac;
  line-height: 24px;
}
.alert-note {
  color: #333;
  background-color: #fef9ee;
  border-color: #edd7a1;
  line-height: 24px;
}
.alert-success {
  color: #333;
  background-color: #eff5e5;
  border-color: #b8d089;
  line-height: 24px;
}
.alert-fail {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #fbeed5;
  line-height: 24px;
}
.alert-span {
  margin-bottom: 0;
}
.green-check {
  padding: 50px 0 0 0;
}
div.row-fluid.wizard-box a.btn {
  margin-top: 6px;
}
div.wizard-content a.btn {
  margin-top: 10px;
}
ul.page-sidebar-menu > li > ul.sub-menu > li.sub-menu-item {
  padding-left: 20px;
}
ul.page-sidebar-menu > li > a {
  border-bottom: 1px solid #314a59 !important;
  color: #fff !important;
}

ul.page-sidebar-menu > li:last-child > a {
  border-bottom: 1px solid #314a59 !important;
}

ul.page-sidebar-menu > li.open > a,
ul.page-sidebar-menu > li > a:hover,
ul.page-sidebar-menu > li:hover > a {
  background: none;
  color: #f2c960 !important;
}

ul.page-sidebar-menu > li.active > a {
  background: #f2c960 !important;
  border-top-color: transparent !important;
  color: #000 !important;
}

ul.page-sidebar-menu > li.active > a i {
  color: #000;
}

ul.page-sidebar-menu > li > ul.sub-menu > li:first-child > a {
  border-top: 0 !important;
}

ul.page-sidebar-menu > li > ul.sub-menu > li.active > a,
ul.page-sidebar-menu > li > ul.sub-menu > li > a:hover {
  color: #f2c960 !important;
  background: none !important;
  text-decoration: underline;
}

ul.page-sidebar-menu > li > ul.sub-menu > li > a:hover {
  background: none !important;
  color: #f2c960 !important;
}

ul.page-sidebar-menu > li > ul.sub-menu li > ul.sub-menu > li.active > a,
ul.page-sidebar-menu > li > ul.sub-menu li > ul.sub-menu > li > a:hover,
ul.page-sidebar-menu > li > ul.sub-menu li.open > a {
  color: #fff !important;
  background: none !important;
}

ul.page-sidebar-menu > li > ul.sub-menu a .arrow:before,
ul.page-sidebar-menu > li > ul.sub-menu a .arrow.open:before {
  color: #707070 !important;
}

ul.page-sidebar-menu > li.active > a .arrow:before,
ul.page-sidebar-menu > li.active > a .arrow.open:before {
  color: #000 !important;
}
ul.page-sidebar-menu > li.active > a .arrow:before,
ul.page-sidebar-menu > li.active > a .arrow.open:after {
  color: #fff !important;
}
.page-sidebar .sidebar-search input {
  background-color: #3d3d3d !important;
  color: #bfbfbf !important;
}

.page-sidebar .sidebar-search .input-box {
  border-bottom: 1px solid #7d7d7d !important;
}

.page-sidebar .sidebar-search .submit {
  //  background-image: url(assets/img/search-icon.png);
}

.sidebar-toggler {
  background-image: url(assets/img/sidebar-toggler.jpg);
  background-color: #242424;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open {
  background-color: #3d3d3d !important;
}

.page-sidebar-closed .page-sidebar .sidebar-search.open .remove {
  //  background-image: url(assets/img/sidebar-search-close.png);
}

.page-sidebar-closed ul.page-sidebar-menu > li:hover .sub-menu {
  background-color: #112c3f;
}

.header .hor-menu .hor-menu-search-form-toggler.hide {
  // background: #000 url(assets/img/hor-menu-search-close.png) no-repeat center;
}

.header .hor-menu .search-form {
  background: #000;
}

.header .hor-menu .search-form .btn {
  color: #d6d6d6;
  //background: #000 url(assets/img/search-icon.png) no-repeat center;
}

.header {
  display: none;
}

.header .nav li.dropdown .dropdown-toggle i,
ul.page-sidebar-menu > li > a > .arrow.open:before {
  color: #8a8a8a !important;
}

ul.page-sidebar-menu > li a i,
ul.page-sidebar-menu li > ul.sub-menu > li > a {
  color: #fff;
}

ul.page-sidebar-menu > li > a .arrow:before,
ul.page-sidebar-menu > li > a .arrow.open:before,
.page-sidebar .sidebar-search input::-webkit-input-placeholder,
.page-sidebar .sidebar-search input:-moz-placeholder,
.page-sidebar .sidebar-search input:-ms-input-placeholder {
  color: #7d7d7d !important;
}

.header .hor-menu .search-form form input,
.header .hor-menu .search-form form input::-webkit-input-placeholder,
.header .hor-menu .search-form form input:-moz-placeholder,
.header .hor-menu .search-form form input::-moz-placeholder,
.header .hor-menu .search-form form input:-ms-input-placeholder {
  color: #d6d6d6;
}

.modal {
  border-radius: 0 0 0 0;
}
.modal-header {
  border-bottom: 1px solid rgb(239, 239, 239);
  background: #15354e;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4 {
  margin: 0px;
  line-height: 30px;
  color: rgb(246, 199, 89);
  font-family: open_sansregular;
  font-size: 14px;
}

.modal-body p + dl,
.modal-body p > dl {
  margin-top: 20px;
}

.modal-body dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.modal-body ol.multi-levelList li {
  font-family: droid_serifregular;
  font-size: 15px;
  color: #333;
  line-height: 1.8;
  padding: 15px 0 0 10px;
}

.modal-body ol.multi-levelList > li:first-child {
  padding-top: 0;
}

.modal-body ol.multi-levelList ol {
  margin-left: 20px;
  display: block;
}

.modal-body ol.multi-levelList > li {
  list-style-type: decimal;
}

.modal-body ol.multi-levelList > li > ol > li {
  list-style-type: lower-alpha;
}

.modal-body ol.multi-levelList > li > ol > li > ol > li {
  list-style-type: decimal;
}

.modal-body dt {
  float: left;
  width: 20%;
  padding: 0;
  margin: 0;
  font-family: 'open_sanssemibold';
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 20px;
  color: #15354e;
  clear: both;
}
.modal-body dd {
  float: left;
  width: 80%;
  /* adjust the width; make sure the total of both is 100% */
  padding: 0;
  padding-bottom: 20px;
  margin: 0;
}

.modal-close {
  padding: 0;
  background-color: transparent;
  border: 0;
  background-image: url(assets/img/button-modal-close.png) !important;
  width: 24px !important;
  height: 23px !important;
}

.modal-close:focus {
  outline: 2px solid #0af;
  outline-offset: 0px;
}

.alert-close {
  float: right;
  font-size: 1.5rem;
  margin-right: -20px;
  opacity: 1;
  padding-top: 0;
}

/*portfolio block*/
.portfolio-block {
  background: #f7f7f7;
  margin-bottom: 15px;
}
.width640-warning {
  display: none;
}
.warning-body {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #333;
  padding-bottom: 10px;
  margin-top: 20px;
}
.warning-body p.intro {
  font-family: droid_serifitalic;
  padding-top: 10px;
  font-size: 18px;
  line-height: 34px;
}
.warning-body p.outro {
  font-family: droid_serifitalic;
  font-size: 14px;
  line-height: 26px;
  margin-top: 10px;
}
.center {
  text-align: center !important;
}

#report-value-row {
  display: none;
}
#not-ascertainable-row {
  display: none;
}
#type-of-income-row {
  display: none;
}
#type-of-income-other-description {
  display: none;
}
#vested-unvested-option {
  display: none;
}
#other-vested-description {
  display: none;
}
#other-unvested-description {
  display: none;
}
#vesting-date {
  display: none;
}
#cash-amount-received-row {
  display: none;
}
#deferred_comp_cash_row {
  display: none;
}
#deferred_comp_asset_row {
  display: none;
}
#investment_plan_row {
  display: none;
}
#asset-income-other-row {
  display: none;
}
#other-deferred-compensation-row {
  display: none;
}
#type_deferred_description_row {
  display: none;
}

blockquote p {
  margin-bottom: 10px;
}

.input-append .add-on,
.input-prepend .add-on {
  height: 18px;
  padding: 10px 5px;
}

.input-append,
.input-prepend {
  margin-bottom: 0;
  width: 93%;
}
.table td,
.table th {
  vertical-align: inherit;
}
.table-report {
  background-color: #fff;
  margin-bottom: 0;
}
.table-report,
.table th {
  padding-top: 10px;
  padding-bottom: 10px;
}
.table-report td {
  text-align: left;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table-report tbody > tr:nth-child(2n) > td,
.table-striped tbody > tr:nth-child(2n) > th {
  background-color: #fff;
}
.table-report tbody tr:last-child {
  border: none;
  border-bottom: #fff;
}
.table-report tbody tr td:last-child {
  padding-right: 0;
}
.table-report tbody tr td:first-child {
  padding-left: 0;
}
.table-report thead tr th:first-child {
  padding-left: 0;
}
.table-report thead tr th {
  font-family: 'open_sanssemibold';
  font-size: 14px;
  color: #15354e;
  background-color: #fff;
  line-height: 35px;
  text-transform: uppercase;
}
.dashboard-body-table {
  background-color: #fff;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #ddd;
  margin: 0 0 30px 0;
}
.dashboard-body a {
  color: #0099cc;
  text-decoration: underline;
}
.dashboard-row a {
  color: #333;
  text-decoration: none;
}
.dashboard-row a:hover {
  text-decoration: underline;
}
.no-zebra tbody > tr:nth-child(2n) > td,
.table-striped tbody > tr:nth-child(2n) > th {
  background-color: #fff;
}
.no-zebra tbody > tr.hidden-row:nth-child(2n) > td,
.table-striped tbody > tr:nth-child(2n) > th {
  background-color: #f9f9f9 !important;
}
input.uneditable-field {
  font-family: 'open_sansregular';
  border: none;
  box-shadow: none;
  padding: 0;
  margin: -13px 0 0 0;
}
.btn-equal-width {
  min-width: 60px;
}
.btn-wrap {
  margin-left: 0 !important;
}

/***
Landscape phone to portrait tablet
***/
@media (max-width: 979px) {
  /***
page sidebar
***/
  .page-sidebar {
    background-color: #292929 !important;
  }

  ul.page-sidebar-menu > li > a {
    border-top: 1px solid #4a4a4a !important;
  }

  ul.page-sidebar-menu > li:last-child > a {
    border-bottom: 0 !important;
  }

  .page-sidebar .sidebar-search input {
    background-color: #292929 !important;
  }

  ul.page-sidebar-menu > li.open > a,
  ul.page-sidebar-menu > li > a:hover,
  ul.page-sidebar-menu > li:hover > a {
    background: none;
  }
}

/* EFEDS-6409 jdg removed Important from margin to restore space between radio buttons and label on 9_0_Spouse_Employ_Assets_Income */
.ui-dform-label {
  display: block;
  margin: 5px 0 0;
  padding: 0;
  margin: 5px 0 0;
  font-family: 'open_sansregular' !important;
  font-style: normal !important;
  text-transform: none !important;
  font-weight: normal !important;
}

.ui-dform-checkboxes > .form-check,
.ui-dform-radiobuttons > .form-check {
  /* padding-left: 0; */
  padding-top: 5px;
}

blockquote {
  margin-top: 10px;
  margin-bottom: 0px;
}

.date-control {
  margin-right: 50px;
}

div.controls-minus-margin span {
  font-family: droid_serifregular;
  font-size: 12px;
  color: rgb(51, 51, 51);
  text-transform: none;
}
div.controls-minus-margin span:after {
  content: '\000A';
  white-space: pre;
}
.white {
  color: #fff !important;
}
.control-group.bigger-font {
  font-size: 16px;
}

.table-toolbar {
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
}
div.actions {
  margin-right: 7px;
}
.toolbar {
  padding-top: 0px;
}
.table-container-mr {
  padding: 20px 0 25px 0;
  margin: 0 0 30px 0;
  background-color: #f5f5f5;
}

a.dod {
  color: #15354e !important;
}

blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  border-left: 5px solid #eeeeee;
}

/* Patches to style to allow 508 */

.form-sidebar-title > * {
  font-family: droid_serifregular;
  margin-top: 32px;
  line-height: 26px;
  font-size: 17.5px;
}

.ngbTooltip .tooltip-inner {
  background-color: #eeeeee;
  color: #000000;
  text-align: center;
  position: absolute;
  z-index: 100;
}

.ngbTooltip .arrow::before {
  border: transparent;
  background-color: transparent;
}

.fa-2x {
  font-size: 1.2em !important;
}

.find-user-modal-container .modal-content {
  height: 400px;
}

.input-group span.input-group-text {
  height: 2.85em;
}

/* for use in date input followed by date picker fields and input followed by trash button icon */
.input-group span.add-on {
  padding: 0;
}

/* for use in fields such as extension days */
.input-group span.text-add-on {
  padding: 10px 5px;
  background-color: #eeeeee;
  border: 1px solid #ccc;
}

.control-group.error input,
.control-group.error select {
  border: 1px solid #b94a48;
}
